import { Wrapper, Message } from './styles';

type ArticleNotificationProps = {
  children?: React.ReactNode;
  message: string;
  type?: 'success' | 'warning' | 'error';
};

const ArticleNotification = ({ message, type }: ArticleNotificationProps) => {
  return (
    <Wrapper>
      <Message type={type}>{message}</Message>
    </Wrapper>
  );
};

export default ArticleNotification;
