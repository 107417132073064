import styled from '@emotion/styled';
import colors from 'assets/colors';

export const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.compBeige_03};
`;
