import colors from 'assets/colors';
import Paragraph, { ParagraphStyleType } from 'components/Paragraph';
import RadioButton from 'components/RadioButton';
import { SyntheticEvent } from 'react';
import RadioButtonListWrapper, { RadioButtonSection, RadioTextWrapper } from './styles';

type RadioItemType = {
  id: string;
  title: string;
  subtitle?: string;
  description?: string;
};

type RadioButtonListProps = {
  radioItems: RadioItemType[];
  handleOnChange: (e: SyntheticEvent) => void;
  selectedId: string;
  buttonsRight?: boolean;
};

const RadioButtonList = ({
  radioItems,
  handleOnChange,
  selectedId,
  buttonsRight,
  ...rest
}: RadioButtonListProps) => {
  return (
    <RadioButtonListWrapper {...rest} role="radiogroup">
      {radioItems?.map((radioItem) => (
        <RadioButtonSection
          htmlFor={radioItem.id}
          key={`location:${radioItem.id}`}
          data-testid="radio-button-section"
          buttonsRight={buttonsRight}
          checked={selectedId === radioItem.id}
        >
          <RadioButton
            id={radioItem.id}
            value={radioItem.title}
            handleOnChange={handleOnChange}
            checked={selectedId === radioItem.id}
          />
          <RadioTextWrapper>
            <Paragraph styleType={ParagraphStyleType.body16} fontColor={colors.black}>
              {radioItem.title}
            </Paragraph>
            {radioItem.subtitle && (
              <Paragraph styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_00}>
                {radioItem.subtitle}
              </Paragraph>
            )}
            {radioItem.description && (
              <Paragraph styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_00}>
                {radioItem.description}
              </Paragraph>
            )}
          </RadioTextWrapper>
        </RadioButtonSection>
      ))}
    </RadioButtonListWrapper>
  );
};

RadioButtonList.defaultProps = {};

export default RadioButtonList;
