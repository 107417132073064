import { Navigate, useLocation, type Location } from 'react-router-dom';
import { useAuth0 } from 'core';
import { getFromLocalStorage, saveToLocalStorage } from 'utils/localStorageUtil';
import { routePaths } from './routePaths';

const saveRedirectPath = (location: Location) => {
  if (location.pathname === routePaths.INTRODUCTION) {
    return;
  }
  saveToLocalStorage('redirectPath', location.pathname);
};

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();

  if (isAuthenticated) {
    return children;
  }

  if (getFromLocalStorage('redirectPath') === undefined) {
    saveRedirectPath(location);
  }
  if (!isLoading) return <Navigate to={routePaths.INTRODUCTION} />;

  return null;
};

export default PrivateRoute;
