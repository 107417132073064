import styled from '@emotion/styled';
import spacing from 'assets/spacing';
import colors from 'assets/colors';

const OrdersListWrapper = styled.div`
  background-color: ${colors.compBeige_03};
  padding-bottom: ${spacing.small};
  min-height: 100%;
`;

export default OrdersListWrapper;
