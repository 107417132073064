import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { getCountryName } from 'utils/locationUtil';
import { selectSendOrderPending, selectErrorSending } from 'state/ordersSlice';
import { selectSelectedLocation } from 'state/locationsSlice';
import Page from 'components/Page';
import Loading from 'components/Loading';
import OrderSuccess from './OrderSuccess';
import OrderFailed from './OrderFailed';
import ConfirmationWrapper, { LoadingWrapper } from './styles';

const OrderLoading = () => {
  const { t } = useTranslation();

  return (
    <LoadingWrapper>
      <Loading text={t('confirmation.loading.text')} />
    </LoadingWrapper>
  );
};

const Confirmation = () => {
  const { t } = useTranslation();
  const sendOrderPending = useSelector(selectSendOrderPending);
  const errorSending = useSelector(selectErrorSending);
  const selectedLocation = useSelector(selectSelectedLocation);

  useEffect(() => {
    if (selectedLocation && !sendOrderPending && errorSending === null) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'OrderSuccessful',
          country: getCountryName(selectedLocation.country),
        },
      });
    }
  }, [selectedLocation, sendOrderPending, errorSending]);

  return (
    <Page
      title={errorSending ? t('confirmation.pageTitle.error') : t('confirmation.pageTitle.success')}
      trackedTitle={
        errorSending
          ? t('confirmation.pageTitle.error', { lng: 'en' })
          : t('confirmation.pageTitle.success', { lng: 'en' })
      }
      showHeader={false}
    >
      <ConfirmationWrapper>
        {sendOrderPending ? <OrderLoading /> : errorSending ? <OrderFailed /> : <OrderSuccess />}
      </ConfirmationWrapper>
    </Page>
  );
};

export default Confirmation;
