import styled from '@emotion/styled';
import { css } from '@emotion/react';
import colors from 'assets/colors';
import fontSizes from 'assets/fontSizes';
import fontWeights from 'assets/fontWeights';
import { ParagraphStyleType } from '.';

const fontStyle = (styleType: ParagraphStyleType) => {
  switch (styleType) {
    case ParagraphStyleType.body12:
      return css`
        font-size: ${fontSizes.body12};
        line-height: 20px;
      `;
    case ParagraphStyleType.body14:
      return css`
        font-size: ${fontSizes.body14};
        line-height: 22px;
      `;
    case ParagraphStyleType.body16:
      return css`
        font-size: ${fontSizes.body16};
        line-height: 24px;
      `;
    case ParagraphStyleType.body18:
    default:
      return css`
        font-size: ${fontSizes.body18};
        line-height: 26px;
      `;
  }
};

export type StyledParagraphProps = {
  fontColor?: string | null;
  center?: boolean | null;
  upperCase?: boolean | null;
  bold?: boolean | null;
  styleType: ParagraphStyleType;
};

export const StyledParagraph = styled.p<StyledParagraphProps>`
  letter-spacing: 0.5px;
  color: ${({ fontColor }) => (fontColor ? fontColor : colors.compCharcoal_00)};
  text-align: ${({ center }) => (center ? 'center' : 'initial')};
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  font-weight: ${({ bold }) => (bold ? fontWeights.bold : fontWeights.normal)};
  ${({ styleType }) => fontStyle(styleType)}
`;
