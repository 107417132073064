import styled from '@emotion/styled';

const ConfirmationWrapper = styled.div`
  height: 100%;
`;

export const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ConfirmationWrapper;
