import { useTranslation } from 'react-i18next';
import { ParagraphStyleType } from 'components/Paragraph';
import { HeadingStyleType, HeadingType } from 'components/Heading';
import { ErrorWrapper, Description, Title, SubTitle, HomeButton } from './styles';
import colors from 'assets/colors';

export interface ErrorMessageProps {
  title?: string;
  subtitle?: string;
  description?: string;
  coverMenu?: boolean;
  buttonURL?: string;
  buttonTitle?: string;
  buttonLinkExternal?: boolean;
  children?: React.ReactNode;
}

const ErrorMessage = ({
  title,
  subtitle,
  description,
  children,
  coverMenu,
  buttonURL,
  buttonTitle,
  buttonLinkExternal,
}: ErrorMessageProps) => {
  const { t } = useTranslation();

  return (
    <ErrorWrapper coverMenu={coverMenu} data-testid="error-message">
      <Title styleType={HeadingStyleType.Hero} headingType={HeadingType.H1} center>
        {title ?? t('errorMessage.title')}
      </Title>
      <SubTitle styleType={HeadingStyleType.Heading4} headingType={HeadingType.H2} center>
        {subtitle ?? t('errorMessage.subtitle')}
      </SubTitle>
      <Description styleType={ParagraphStyleType.body18} center>
        {description ?? t('errorMessage.description')}
      </Description>
      <nav>
        <HomeButton
          href={buttonURL ? buttonURL : '/'}
          bgColor={colors.primGreen_00}
          target={buttonLinkExternal ? '_blank' : undefined}
          upperCase
        >
          {buttonTitle ? buttonTitle : t('notFound.cta.text')}
        </HomeButton>
      </nav>
      {children}
    </ErrorWrapper>
  );
};

export default ErrorMessage;
