import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import colors from 'assets/colors';
import spacing from 'assets/spacing';
import { motion } from 'framer-motion';

type DialogOverlayProps = {
  fullscreen: boolean | null | undefined;
  positionDown: boolean | null | undefined;
};

type CloseIconProps = {
  positionDown: boolean | null | undefined;
};

export const DialogOverlay = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop),
})<DialogOverlayProps>`
  height: 100%;
  max-height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: ${({ positionDown }) => (positionDown ? 'flex-end' : 'center')};
  align-items: center;
  z-index: 5;
  background-color: ${colors.transparentGray};
  padding: ${({ fullscreen }) => (fullscreen ? '0' : spacing.small)};
`;

type MotionDialogWrapperProps = {
  fullscreen: boolean | null | undefined;
  positionDown: boolean | null | undefined;
};

export const MotionDialogWrapper = styled(motion.div)<MotionDialogWrapperProps>`
  position: relative;
  height: ${({ fullscreen }) => (fullscreen ? '100%' : 'auto')};
  width: 100%;
  max-width: calc(${spacing.maxWidth} - ${({ fullscreen }) => (fullscreen ? '0px' : '48px')});
  padding: ${spacing.small};
  padding-top: ${({ positionDown }) => (positionDown ? spacing.mediumLow : spacing.mediumHigh)};
  padding-bottom: ${({ fullscreen }) => (fullscreen ? spacing.mediumHigh : spacing.mediumLow)};
  background-color: ${colors.white};
  border-radius: ${({ fullscreen }) => (fullscreen ? '0' : '8px')};
`;

export const CloseIconWrapper = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop),
})<CloseIconProps>`
  position: absolute;
  cursor: pointer;
  top: ${spacing.xsmall};
  right: ${spacing.xsmall};
`;
