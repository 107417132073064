import { motion } from 'framer-motion';
import { CircleMotion } from './styles';

const ConfirmationCheckIcon = () => {
  const circle = {
    success: {
      scale: [0.3, 1],
      transition: {
        when: 'beforeChildren',
        duration: 0.5,
        type: 'spring',
      },
    },
  };
  const check = {
    success: { pathLength: [0, 1], transition: { duration: 0.4 }, opacity: [0, 1, 1, 1] },
  };

  return (
    <CircleMotion variants={circle} animate="success">
      <motion.svg
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M4 12L9 17L20 6"
          // d="M20 6L9 17L4 12"
          stroke="#98B347"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          variants={check}
          initial={{ opacity: 0, pathLength: 0 }}
        />
      </motion.svg>
    </CircleMotion>
  );
};

export default ConfirmationCheckIcon;
