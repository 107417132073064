import { StyledParagraph, StyledParagraphProps } from './styles';
import { ReactNode } from 'react';

export enum ParagraphStyleType {
  body18 = 'body18',
  body16 = 'body16',
  body14 = 'body14',
  body12 = 'body12',
}

type ParagraphProps = StyledParagraphProps & {
  children?: ReactNode;
};

function Paragraph(props: ParagraphProps) {
  return <StyledParagraph {...props} />;
}

export default Paragraph;
