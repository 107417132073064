/* eslint-disable prettier/prettier */
import styled from '@emotion/styled';
import colors from 'assets/colors';
import fontSizes from 'assets/fontSizes';
import fontWeights from 'assets/fontWeights';
import spacing from 'assets/spacing';
import { AnchorButtonProps } from '.';

const StyledAnchorButton = styled.a<Pick<AnchorButtonProps, 'bgColor' | 'upperCase'>>`
  cursor: pointer;
  display: block;
  color: ${colors.white};
  background-color: ${({ bgColor }) => bgColor || colors.compBlue_00};
  padding: ${spacing.xsmall} ${spacing.small};

  font-size: ${fontSizes.body16};
  line-height: 24px;
  font-weight: ${fontWeights.bold};
  letter-spacing: 1.5px;
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  text-decoration: none;
  text-align: center;
`;

export default StyledAnchorButton;
