import { CircleMotion, StyledPinIcon } from './styles';

const LocationPin = () => {
  const circle = {
    animation: {
      scale: [0.3, 1],
      transition: {
        when: 'beforeChildren',
        duration: 0.5,
        type: 'spring',
      },
    },
  };

  return (
    <CircleMotion initial={{ scale: 0.3 }} variants={circle} animate="animation">
      <StyledPinIcon />
    </CircleMotion>
  );
};

export default LocationPin;
