import { PartnerResponse, Location, LocationAddress } from '@stenarecycling/customer-portal-types';
import { Country } from 'types/location';

const hasValidText = (text?: string | null) => {
  if (!text) {
    return false;
  }

  const regex = /(n|N)\/(a|A)/;
  const isNotApplicable = regex.test(text);

  return !isNotApplicable;
};

const convertPartnerToLocation = (partner: PartnerResponse): Location => {
  const {
    dwKey,
    id,
    name,
    city,
    street,
    postalCode,
    companyId,
    countryId,
    childCount,
    customerText,
    additionalDescription,
    pickupAddressId,
    hasActiveChildren,
    source,
  } = partner;

  const address: LocationAddress = {
    street: hasValidText(street) ? street.trim() : '',
    zipcode: postalCode,
    city,
  };

  const location: Location = {
    dwKey,
    id,
    name: name.trim(),
    address,
    country: companyId,
    countryId,
    childCount,
    pickupAddressId,
    hasActiveChildren,
    source,
  };

  if (hasValidText(customerText)) {
    return { ...location, description: customerText.trim() };
  }

  if (hasValidText(additionalDescription)) {
    return { ...location, description: additionalDescription.trim() };
  }

  return location;
};

const sortLocationsAscending = (locations: Location[]) =>
  [...locations].sort((a, b) => {
    if (a.name.localeCompare(b.name, 'sv', { sensitivity: 'base' }) === 0) {
      if (
        !a.description ||
        !b.description ||
        a.description.localeCompare(b.description, 'sv', { sensitivity: 'base' }) === 0
      ) {
        const streetA = a.address.street;
        const streetB = b.address.street;
        return streetA.localeCompare(streetB, 'sv', { sensitivity: 'base' }) < 0 ? -1 : 1;
      }
      return a.description.localeCompare(b.description, 'sv', { sensitivity: 'base' }) < 0 ? -1 : 1;
    }
    return a.name.localeCompare(b.name, 'sv', { sensitivity: 'base' }) < 0 ? -1 : 1;
  });

export const createLocations = (partners: PartnerResponse[]): Location[] => {
  const activePartners = partners.filter((partner) => {
    if (partner.companyId === Country.SWEDEN) {
      return partner.isActive;
    }

    return partner.isActivePickupPlace;
  });

  const locations = activePartners.map((partner) => convertPartnerToLocation(partner));

  return sortLocationsAscending(locations);
};

export const getCountryName = (countryCode: Country) => {
  const indexOfItem = Object.values(Country).indexOf(countryCode as Country);

  if (indexOfItem >= 0) {
    return Object.keys(Country)[indexOfItem];
  }

  return 'Unknown';
};
