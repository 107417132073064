const userAgents = () => true;

export const isIos = () => {
  const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAppleDevice = navigator.userAgent.includes('Macintosh');
  const isTouchScreen = navigator.maxTouchPoints >= 1;
  const isIpadAfterV13 = isAppleDevice && isTouchScreen;
  return !window.MSStream && (isIos || isIpadAfterV13);
};

export const isSafari = () => {
  return navigator.userAgent.includes('Safari');
};

export const isEdgeIos = () => {
  return navigator.userAgent.includes('EdgiOS');
};

export const isChromeIos = () => {
  return navigator.userAgent.includes('CriOS');
};

export const isFireFoxIos = () => {
  return navigator.userAgent.includes('FxiOS');
};

export const isOpera = () => {
  return navigator.userAgent.includes('OPiOS');
};

export default userAgents;
