import styled from '@emotion/styled';
import spacing from 'assets/spacing';
import colors from 'assets/colors';
import Paragraph from 'components/Paragraph';

const CustomerServiceWrapper = styled.div`
  background-color: ${colors.white};
  min-height: 100%;
`;

export const StyledParagraph = styled(Paragraph)`
  padding-bottom: ${spacing.small};
`;

export const StyledA = styled.a`
  color: ${colors.black};
`;

export const CustomerServiceTitle = styled.div`
  padding: ${spacing.small};
`;

export default CustomerServiceWrapper;
