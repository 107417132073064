export interface Language {
  code: string;
  name: string;
  englishName: string;
}

export interface Country {
  code: string;
  name: string;
  englishName: string;
}

export const Languages = {
  en: {
    code: 'en',
    name: 'English',
    englishName: 'English',
  },
  da: {
    code: 'da',
    name: 'Dansk',
    englishName: 'Danish',
  },
  sv: {
    code: 'sv',
    name: 'Svenska',
    englishName: 'Swedish',
  },
  fi: {
    code: 'fi',
    name: 'Suomi',
    englishName: 'Suomi',
  },
  no: {
    code: 'no',
    name: 'Norsk',
    englishName: 'Norwegian',
  },
};

export const Countries = {
  DK: {
    code: 'DK',
    name: 'Danmark',
    englishName: 'Denmark',
  },
};

export const supportedLngs = Object.values(Languages).map((val) => val.code);
