import { useEffect, useState } from 'react';

export const useDialog = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = () => {
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) return;

    const handleOnKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleOnKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleOnKeyDown, false);
    };
  }, [isOpen]);

  return { isOpen, open, close };
};
