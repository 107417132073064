import { motion } from 'framer-motion';
import { type InferProps } from 'prop-types';
import { SpinnerWrapper, StyledMotionSvg } from './styles';

function Spinner(props: InferProps<typeof Spinner.propTypes>) {
  const spinnerSize = 48;
  const spinner = {
    animation: {
      transition: { duration: 4, repeat: Infinity, repeatType: 'loop' as const, ease: 'linear' },
      strokeDashoffset: [0.66 * spinnerSize, 3.14 * spinnerSize, 0.66 * spinnerSize],
      rotate: ['0deg', '720deg', '1080deg'],
    },
  };

  return (
    <SpinnerWrapper {...props}>
      <StyledMotionSvg width="48" height="48" viewBox="0 0 48 48" animate="animation">
        <motion.circle
          className="path"
          fill="none"
          strokeWidth="4"
          strokeLinecap="round"
          cx="24"
          cy="24"
          r="24"
          stroke="#6A8171"
          strokeDasharray={3.14 * spinnerSize}
          variants={spinner}
        />
      </StyledMotionSvg>
    </SpinnerWrapper>
  );
}

Spinner.propTypes = {};

export default Spinner;
