import { useLocation } from 'react-router-dom';
import { routePaths } from 'routes/routePaths';
import useHideBottomMenu from 'utils/hooks/useHideBottomMenu';
import { getViewName, isCurrentRoute } from 'utils/routeUtil';
import truckIcon from 'assets/icons/truck.svg';
import cogIcon from 'assets/icons/cog.svg';
import calendarIcon from 'assets/icons/calendar.svg';
import MenuButton from './MenuButton';
import MenuWrapper, { Nav } from './styles';
import { useTranslation } from 'react-i18next';

const Menu = () => {
  const location = useLocation();
  const hideMenu = useHideBottomMenu();
  const { t } = useTranslation();

  const hasAnyMatchingPath = (paths: string[]) =>
    paths.some((path) => isCurrentRoute(path, location));

  const getRouterStateIfFromPath = (path: string) => {
    if (isCurrentRoute(path, location)) {
      return { from: path };
    }
    return {};
  };

  return hideMenu ? null : (
    <MenuWrapper data-testid="bottom-menu">
      <Nav>
        <MenuButton
          to={routePaths.PLACE_ORDER}
          iconUrl={truckIcon}
          text={getViewName(routePaths.PLACE_ORDER, t)}
          isActive={hasAnyMatchingPath([routePaths.PLACE_ORDER, routePaths.CONFIRMATION])}
        />
        <MenuButton
          to={routePaths.USER_ORDERS}
          state={getRouterStateIfFromPath(routePaths.USER_ORDERS_DETAILS)}
          iconUrl={calendarIcon}
          text={getViewName(routePaths.USER_ORDERS, t)}
          isActive={hasAnyMatchingPath([routePaths.USER_ORDERS, routePaths.USER_ORDERS_DETAILS])}
        />
        <MenuButton
          to={routePaths.SETTINGS}
          state={getRouterStateIfFromPath(routePaths.PRIVACY_INFORMATION)}
          iconUrl={cogIcon}
          text={t('userProfile.settings')}
          isActive={hasAnyMatchingPath([
            routePaths.SETTINGS,
            routePaths.PRIVACY_INFORMATION,
            routePaths.COOKIES,
            routePaths.CHANGE_LANGUAGE,
            routePaths.FEEDBACK_AND_HELP,
          ])}
        />
      </Nav>
    </MenuWrapper>
  );
};

export default Menu;
