import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import spacing from 'assets/spacing';
import colors from 'assets/colors';
import { Link, LinkProps } from 'react-router-dom';
import fontSizes from 'assets/fontSizes';
import fontWeights from 'assets/fontWeights';

interface LinkButtonProps extends LinkProps {
  isActive?: boolean | null;
  upperCase?: boolean | null;
  bgColor?: string | null;
  textColor?: string | null;
}

const LinkButtonWrapper = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<LinkButtonProps>`
  cursor: pointer;
  display: block;
  color: ${({ textColor }) => textColor || colors.white};
  background-color: ${({ bgColor }) => bgColor || colors.primGreen_00};
  padding: ${spacing.xsmall} ${spacing.small};

  font-size: ${fontSizes.body16};
  line-height: 24px;
  font-weight: ${fontWeights.bold};
  letter-spacing: 1.5px;
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  text-decoration: none;
  text-align: center;
`;

export default LinkButtonWrapper;
