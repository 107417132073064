import styled from '@emotion/styled';
import colors from 'assets/colors';
import spacing from 'assets/spacing';
import Paragraph from 'components/Paragraph';
import GlobeIcon from 'assets/icons/globe.svg?react';
import ChevronIcon from 'assets/icons/chevron-right.svg?react';

export const NoAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoAccountTitleParagraph = styled(Paragraph)`
  padding: ${spacing.xsmall} 0 ${spacing.mediumLow} 0;
`;

export const NoAccountParagraph = styled(Paragraph)`
  padding: ${spacing.medium} ${spacing.small} ${spacing.small} ${spacing.small};
`;

export const CountryLinkList = styled.div`
  width: calc(100% + ${spacing.small} + ${spacing.small});
  position: relative;
  bottom: -${spacing.xsmall};
  left: -${spacing.small};
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.primGrey_03};
`;

export const BlockATag = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${spacing.small};
  margin: 0;
  background-color: ${colors.white};
  border: none;
  border-bottom: 1px solid ${colors.primGrey_03};
  text-decoration: none;
  &:first-of-type {
    border-top: 1px solid ${colors.primGrey_03};
  }
  &:hover {
    cursor: pointer;
`;

export const StyledGlobeIcon = styled(GlobeIcon)`
  path {
    stroke: ${colors.compBlue_00};
  }
  circle {
    stroke: ${colors.compBlue_00};
  }
`;

export const StyledChevronIcon = styled(ChevronIcon)`
  path {
    stroke: ${colors.compBlue_00};
  }
  circle {
    stroke: ${colors.compBlue_00};
  }
`;
