import { Location } from '@stenarecycling/customer-portal-types';
import { ParagraphStyleType } from 'components/Paragraph';
import { HeadingStyleType } from 'components/Heading';
import LocationPin from './LocationPin';
import OrderLocationWrapper, { TextWrapper, Name, Description, Address } from './styles';

export type OrderLocationProps = {
  location: Location;
};

const OrderLocation = ({ location }: OrderLocationProps) => {
  const { name, id, description, address } = location;
  const { street, city } = address;

  return (
    <OrderLocationWrapper>
      <LocationPin />
      <TextWrapper>
        <Name styleType={HeadingStyleType.Heading7}>{name}</Name>
        {description && (
          <Description styleType={ParagraphStyleType.body14}>{description}</Description>
        )}
        <Address styleType={ParagraphStyleType.body14}>{`${street}, ${city} (${id})`}</Address>
      </TextWrapper>
    </OrderLocationWrapper>
  );
};

export default OrderLocation;
