import { EasterEgg } from '..';

const forceReload: EasterEgg = {
  eventType: 'click',
  callback: (sequence) => {
    const newApiFlagTrigger = sequence
      .map((event) => event.type)
      .join('')
      .includes('clickclickclickclickclick');

    if (newApiFlagTrigger) {
      window.location.reload();

      return true;
    }

    return false;
  },
};

export default forceReload;
