import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { type SendOrderType } from 'types/order';
import { Country } from 'types/location';
import { routePaths } from 'routes/routePaths';
import { useEventTracking, useProfile } from 'core';
import { addSelectedRoute } from 'utils/articleUtil';
import { useDispatch } from 'utils/hooks/redux';
import { useApi } from 'utils/hooks/useApi';
import { isNotCompleted } from 'utils/orderUtil';
import {
  setWeight,
  setComment,
  resetWeight,
  resetComment,
  decrementNumber,
  incrementNumber,
  selectAvailableArticles,
  selectSelectedArticles,
  selectLoadingArticles,
  resetNumberOfItems,
} from 'state/articlesSlice';
import {
  selectLocations,
  selectSelectedLocation,
  updateSelectedLocation,
} from 'state/locationsSlice';
import { selectAllOrders } from 'state/ordersSlice';
import { setConfirmationData } from 'state/confirmationSlice';
import Page from 'components/Page';
import Loading from 'components/Loading';
import ArticlesList from './ArticlesList';
import Location from './Location';
import NoLocationsAvailable from './NoLocationsAvailable';
import NoArticlesAvailable from './NoArticlesAvailable';
import SubmitButton from './SubmitButton';
import PlaceOrderWrapper, { LoadingWrapper } from './styles';

const PlaceOrder = () => {
  const { t } = useTranslation();
  const loadingArticles = useSelector(selectLoadingArticles);
  const articles = useSelector(selectAvailableArticles);
  const selectedArticles = useSelector(selectSelectedArticles);
  const locations = useSelector(selectLocations);
  const selectedLocation = useSelector(selectSelectedLocation);
  const allOrders = useSelector(selectAllOrders);
  const dispatch = useDispatch();

  const { sendOrder, fetchArticles, fetchAllOrders } = useApi();
  const { trackEvent } = useEventTracking();

  const [profile] = useProfile();
  const { fullName, email, phone } = profile || {};
  const userContact = {
    fullname: fullName ?? '',
    email: email ?? '',
    phone: phone ?? '',
  };

  const handleOnSaveWeight = (id: string, weight: number) => {
    dispatch(setWeight({ id, weight }));
  };

  const handleOnSaveComment = (id: string, comment: string | null) => {
    dispatch(setComment({ id, comment }));
  };

  const decrement = (id: string) => {
    trackEvent('order_delete');
    dispatch(decrementNumber(id));
  };

  const increment = (id: string) => {
    trackEvent('order_add');
    dispatch(incrementNumber(id));
  };

  const handleUpdateSelectedLocation = (locationId: string) => {
    dispatch(updateSelectedLocation(locationId));

    const newLocation = locations.find((location) => location.dwKey === locationId);

    if (newLocation) {
      fetchArticles({
        partnerId: newLocation.dwKey,
        pickupAddressId: newLocation.pickupAddressId,
      });
    }
  };

  const navigate = useNavigate();
  const submitOrder = () => {
    const selectedArticlesWithSelectedRoute = addSelectedRoute(selectedArticles);

    const sendOrderData: Omit<SendOrderType, 'token'> = {
      dwKey: selectedLocation!.dwKey, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      locationId: selectedLocation!.id, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      userContact,
      articles: selectedArticlesWithSelectedRoute,
    };

    // Only send pickup address id if location is not Danish or Swedish
    if (
      selectedLocation?.country !== Country.DENMARK &&
      selectedLocation?.country !== Country.SWEDEN
    ) {
      sendOrderData.pickupAddressId = selectedLocation?.pickupAddressId;
    }

    // Extract articles with routes grouped by RouteId as different orders

    const routedOrders: SendOrderType[] = [];
    const checkedRouteIds: number[] = [];

    sendOrderData.articles.forEach((article) => {
      if ('routesInfo' in article) {
        const routesInfo = article.routesInfo;

        if (
          routesInfo &&
          routesInfo.length > 0 &&
          !checkedRouteIds.includes(routesInfo[0].RouteId)
        ) {
          const articlesWithSameRouteId = sendOrderData.articles.filter((article) => {
            if ('routesInfo' in article) {
              const routesInfo2 = article.routesInfo;

              return routesInfo2 && routesInfo2[0].RouteId === routesInfo[0].RouteId;
            }

            return false;
          });

          if (articlesWithSameRouteId.length > 0) {
            routedOrders.push({
              ...sendOrderData,
              articles: articlesWithSameRouteId,
            });
          }

          checkedRouteIds.push(routesInfo[0].RouteId);
        }
      }
    });

    if (routedOrders.length > 0) {
      routedOrders.forEach((order) => {
        sendOrder(order);
      });
    }

    // Filter out articles with routes
    sendOrderData.articles = sendOrderData.articles.filter((article) => {
      const hasRoute =
        'routesInfo' in article && article.routesInfo && article.routesInfo.length > 0;

      return !hasRoute;
    });
    if (sendOrderData.articles.length > 0) {
      sendOrder(sendOrderData);
    }

    trackEvent('order_submit');

    dispatch(
      setConfirmationData({
        articles: selectedArticlesWithSelectedRoute,
        location: selectedLocation,
      }),
    );

    const hazardousWastes = selectedArticles.filter((article) => article.hazardousWaste);

    hazardousWastes.forEach((hazardousWaste) => {
      if (selectedLocation?.country === Country.SWEDEN) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'SwedenHazardousWaste',
            weight: hazardousWaste.weight,
          },
        });
      } else if (selectedLocation?.country === Country.DENMARK) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'DenmarkHazardousWaste',
            weight: hazardousWaste.weight,
          },
        });
      }
    });

    dispatch(resetNumberOfItems());
    dispatch(resetWeight());
    dispatch(resetComment());

    navigate(routePaths.CONFIRMATION);
  };

  useEffect(() => {
    if (selectedLocation?.dwKey) {
      fetchAllOrders({ partnerId: selectedLocation?.dwKey });
    }
  }, [selectedLocation?.dwKey]);

  let activeOrdersInSelectedLocation;

  if (selectedLocation?.dwKey && allOrders) {
    activeOrdersInSelectedLocation = allOrders[selectedLocation.dwKey]?.filter((order) =>
      isNotCompleted(order),
    );
  }

  const { country } = selectedLocation || {};

  return (
    <Page trackedTitle="Place order">
      <PlaceOrderWrapper>
        {locations.length === 0 ? (
          <NoLocationsAvailable />
        ) : (
          <>
            <Location
              updateSelectedLocation={handleUpdateSelectedLocation}
              initialLocation={selectedLocation}
              locations={locations}
              buttonsRight={true}
            />
            {loadingArticles && (
              <LoadingWrapper>
                <Loading text={t('placeOrder.loadingArticles')} />
              </LoadingWrapper>
            )}
            {!loadingArticles &&
              (articles.length === 0 ? (
                <NoArticlesAvailable />
              ) : (
                <>
                  <ArticlesList
                    {...{
                      articles,
                      decrement,
                      increment,
                      handleOnSaveComment,
                      handleOnSaveWeight,
                      selectedLocation,
                      activeOrdersInSelectedLocation,
                      country,
                    }}
                  />
                  <SubmitButton
                    onClick={submitOrder}
                    disabled={selectedArticles.length === 0}
                    sticky={articles.length > 4 && selectedArticles.length > 0}
                  >
                    {t('placeOrder.submitOrderButton')}
                  </SubmitButton>
                </>
              ))}
          </>
        )}
      </PlaceOrderWrapper>
    </Page>
  );
};

export default PlaceOrder;
