import styled from '@emotion/styled';
import ArrowRightIcon from 'assets/icons/arrow-right.svg?react';
import spacing from 'assets/spacing';
import colors from 'assets/colors';
import ATag from 'components/ATag';
import Paragraph from 'components/Paragraph';

const OrderItemWrapper = styled.div`
  padding: ${spacing.xsmall};
  margin: ${spacing.xxsmall} 0;
  background-color: ${colors.white};
`;

export const OrderItemLink = styled(ATag)`
  &:link,
  &:visited {
    text-decoration: inherit;
    color: inherit;
  }
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.compBeige_02};
`;

export const Text = styled.div`
  margin-right: ${spacing.xsmall};
`;

export const Title = styled(Paragraph)`
  color: ${colors.compCharcoal_00};
`;

export const Description = styled(Paragraph)`
  margin: ${spacing.tiny} 0;
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  flex-basis: 10%;
  path {
    stroke: ${colors.compBlue_00};
  }
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${spacing.xxsmall};
  margin-right: ${spacing.mediumHigh};
`;

export const Label = styled(Paragraph)`
  color: ${colors.primGrey_01};
`;

export const Value = styled(Paragraph)`
  color: ${colors.compCharcoal_00};
`;

export default OrderItemWrapper;
