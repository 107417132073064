import { combineReducers, configureStore, type PreloadedState } from '@reduxjs/toolkit';
import locationsReducer from './locationsSlice';
import articlesReducer from './articlesSlice';
import ordersReducer from './ordersSlice';
import confirmationReducer from './confirmationSlice';
import { listenerMiddleware } from './listenerMiddleware';

const rootReducer = combineReducers({
  locations: locationsReducer,
  articles: articlesReducer,
  orders: ordersReducer,
  confirmation: confirmationReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(listenerMiddleware.middleware),

    devTools: true,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

const store = setupStore();

export default store;
