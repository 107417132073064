import styled from '@emotion/styled';
import colors from 'assets/colors';
import fontSizes from 'assets/fontSizes';
import fontWeights from 'assets/fontWeights';
import spacing from 'assets/spacing';
import ATag from 'components/ATag';
import ChevronIcon from 'assets/icons/chevron-right.svg?react';
import UserIcon from 'assets/icons/user.svg?react';
import { Link } from 'react-router-dom';
import Paragraph from 'components/Paragraph';

export const UserProfileWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const StyledNameEmailParagraph = styled(Paragraph)`
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.xsmall};
  padding: ${spacing.mediumLow} ${spacing.small};
  border-bottom: 1px solid ${colors.primGrey_03};
`;

export const BlockLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: StenaSans;
  font-style: normal;
  font-weight: ${fontWeights.normal};
  font-size: ${fontSizes.body16};
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${colors.black};
  text-decoration: none;

  width: 100%;
  padding: ${spacing.small};
  margin: 0;
  background-color: ${colors.white};
  text-align: left;
  border: none;
  border-bottom: 1px solid ${colors.primGrey_03};
  &:first-of-type {
    border-top: 1px solid ${colors.primGrey_03};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const StyledChevronIcon = styled(ChevronIcon)`
  path {
    stroke: ${colors.compBlue_00};
  }
  circle {
    stroke: ${colors.compBlue_00};
  }
`;

export const LogOutWrapper = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: content-box;
  padding: ${spacing.mediumHigh} ${spacing.small};
  max-width: calc(${spacing.maxWidth} / 2);
  margin: auto;
`;

export const LogOutLink = styled(ATag)`
  font-size: ${fontSizes.body18};
  font-weight: ${fontWeights.bold};
  line-height: 26px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${colors.danger_00};
`;

export const AvatarCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primGreen_00};
  width: 56px;
  height: 56px;
  border-radius: 999rem;
`;

export const StyledUserIcon = styled(UserIcon)`
  width: 28px;
  height: 28px;
  path {
    stroke: ${colors.white};
  }
  circle {
    stroke: ${colors.white};
  }
`;
