import { useEffect, useState } from 'react';
import { type BranchContactType } from 'types/branchContact';
import { getBranchContact } from 'api/branchContact';
import ATag from 'components/ATag';
import { HeadingStyleType, HeadingType } from 'components/Heading';
import Paragraph, { ParagraphStyleType } from 'components/Paragraph';
import { useAuth0, useTransactionId } from 'core';
import Loading from 'components/Loading';
import {
  BranchContactInfoWrapper,
  ContactInfoSection,
  LoadingWrapper,
  StyledHeading,
} from './styles';

import { useTranslation } from 'react-i18next';

type BranchContactInfoProps = {
  pickupPlaceId?: string;
};

const BranchContactInfo = ({ pickupPlaceId }: BranchContactInfoProps) => {
  const [branchContact, setBranchContact] = useState<BranchContactType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();
  const transactionId = useTransactionId();

  useEffect(() => {
    const fetchBranch = async () => {
      if (pickupPlaceId) {
        setIsLoading(true);
        const token = await getAccessTokenSilently();

        getBranchContact(token, transactionId, pickupPlaceId).then((response) => {
          if (response?.data) {
            setBranchContact(response?.data);
          }
          setIsLoading(false);
        });
      }
    };

    fetchBranch();
  }, [pickupPlaceId]);

  return (
    <BranchContactInfoWrapper>
      {isLoading ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <Content branchContact={branchContact} />
      )}
    </BranchContactInfoWrapper>
  );
};

type ContentProps = {
  branchContact: BranchContactType | null;
};
const Content = ({ branchContact }: ContentProps) => {
  const { t } = useTranslation();

  if (!branchContact) {
    return (
      <Paragraph styleType={ParagraphStyleType.body16}>
        {t('userProfile.noContactInfo.text')}
      </Paragraph>
    );
  }

  return (
    <>
      <StyledHeading styleType={HeadingStyleType.Heading6} headingType={HeadingType.H2} upperCase>
        {t('userProfile.contactInformation.header')}
      </StyledHeading>

      <ContactInfoSection>
        <Paragraph styleType={ParagraphStyleType.body14} upperCase>
          {t('userProfile.phone.subHeader')}
        </Paragraph>
        <Paragraph styleType={ParagraphStyleType.body16}>
          <ATag href={`tel:${branchContact.phone}`}>{branchContact.phone}</ATag>
        </Paragraph>
      </ContactInfoSection>

      <ContactInfoSection>
        <Paragraph styleType={ParagraphStyleType.body14} upperCase>
          {t('userProfile.email.subHeader')}
        </Paragraph>
        <ATag href={`mailto:${branchContact.email}`}>{branchContact.email}</ATag>
      </ContactInfoSection>

      <ContactInfoSection>
        <Paragraph styleType={ParagraphStyleType.body14} upperCase>
          {t('userProfile.address.subHeader')}
        </Paragraph>
        <Paragraph styleType={ParagraphStyleType.body16}>{`${branchContact.address},`}</Paragraph>
        <Paragraph styleType={ParagraphStyleType.body16}>{branchContact.city}</Paragraph>
      </ContactInfoSection>
    </>
  );
};

export default BranchContactInfo;
