import styled from '@emotion/styled';
import { spacing } from '@stenametall/component-library/dist/themes/stena-recycling';

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100%;
  width: 100%;
  max-width: ${spacing.maxWidth};
`;
