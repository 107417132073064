import { useTranslation } from 'react-i18next';
import { routePaths } from 'routes/routePaths';
import colors from 'assets/colors';
import Page from 'components/Page';
import LinkButton from 'components/LinkButton';
import { HeadingType, HeadingStyleType } from 'components/Heading';
import { ParagraphStyleType } from 'components/Paragraph';
import DocumentsIcon from './DocumentsIcon';
import { NoOrdersWrapper, ContentWrapper, StyledHeading, StyledParagraph, Nav } from './styles';

const NoOrders = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <NoOrdersWrapper>
        <ContentWrapper>
          <DocumentsIcon />
          <StyledHeading
            headingType={HeadingType.H2}
            styleType={HeadingStyleType.Heading6}
            center
            upperCase
          >
            {t('userOrder.no.active.orders')}
          </StyledHeading>
          <StyledParagraph styleType={ParagraphStyleType.body16} center>
            {t('userOrder.no.active.orders.message')}
          </StyledParagraph>
        </ContentWrapper>
        <Nav>
          <LinkButton to={routePaths.PLACE_ORDER} bgColor={colors.info_00} upperCase>
            {t('userOrder.cta.text')}
          </LinkButton>
        </Nav>
      </NoOrdersWrapper>
    </Page>
  );
};

export default NoOrders;
