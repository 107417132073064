import styled from '@emotion/styled';
import colors from 'assets/colors';
import fontSizes from 'assets/fontSizes';
import spacing from 'assets/spacing';
import Paragraph from 'components/Paragraph';
import breakpoints from 'assets/breakpoints';

const ArticleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: ${spacing.xsmall} ${spacing.small};
  margin: 0 -${spacing.small};
  border-top: 1px solid ${colors.beige};
  &:last-child {
    border-bottom: 1px solid ${colors.beige};
  }
  background-color: ${({ numberOfItems }) => (numberOfItems > 0 ? colors.beige : colors.white)};
`;

export const Content = styled.div`
  margin-right: ${spacing.xsmall};
`;

export const Title = styled(Paragraph)`
  color: ${colors.compCharcoal_00};
  font-weight: fontWeights.medium;
`;

export const Description = styled(Paragraph)`
  color: ${colors.primGrey_01};
  margin: ${spacing.tiny} 0;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  font-size: ${fontSizes.body18};
`;

export const NumberOfItems = styled.div`
  margin: 0 ${spacing.xxsmall};
  width: 1.5rem;
  text-align: center;
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 ${spacing.small};
  }
`;

export const AddComment = styled.div`
  position: relative;
  left: -${spacing.small};
  padding: 0 ${spacing.xsmall} ${spacing.xsmall} ${spacing.small};
  width: calc(100% + ${spacing.small} + ${spacing.small});
  background-color: ${colors.beige};
  a {
    font-size: ${fontSizes.body16};
  }
`;

export const TopBorder = styled.div`
  border-top: 1px solid ${colors.compBeige_02};
  padding: ${spacing.xxsmall};
`;

export default ArticleWrapper;
