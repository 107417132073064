import styled from '@emotion/styled';

const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;
`;

export default MainWrapper;
