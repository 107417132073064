import { Navigate } from 'react-router-dom';
import { routePaths } from './routePaths';
import Introduction from 'views/Introduction';
import PlaceOrder from 'views/PlaceOrder';
import Confirmation from 'views/Confirmation';
import UserOrders from 'views/UserOrders';
import OrdersList from 'views/UserOrders/OrdersList';
import OrderDetails from 'views/UserOrders/OrderDetails';
import UserProfile from 'views/UserProfile';
import UserProfileIndex from 'views/UserProfile/UserProfileIndex';
import PrivacyInformation from 'views/UserProfile/PrivacyInformation';
import NotFound from 'views/NotFound';
import Cookies from 'views/UserProfile/Cookies';
import ChangeLanguage from 'views/UserProfile/ChangeLanguage';
import FeedbackAndHelp from 'views/UserProfile/FeedbackAndHelp';
import YourBranch from 'views/UserProfile/YourBranch';
import CustomerService from 'views/UserProfile/CustomerService';
import { getFromLocalStorage } from 'utils/localStorageUtil';
import PrivateRoute from './privateRoute';
import { useAuth0 } from 'core';

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    const pathname = getFromLocalStorage('redirectPath') ?? routePaths.PLACE_ORDER;

    return <Navigate to={pathname} />;
  }

  return children;
};

const routes = [
  {
    path: routePaths.INTRODUCTION,
    element: (
      <PublicRoute>
        <Introduction />
      </PublicRoute>
    ),
  },
  {
    path: routePaths.PLACE_ORDER,
    element: (
      <PrivateRoute>
        <PlaceOrder />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.CONFIRMATION,
    element: (
      <PrivateRoute>
        <Confirmation />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.USER_ORDERS,
    element: (
      <PrivateRoute>
        <UserOrders />
      </PrivateRoute>
    ),
    children: [
      {
        path: `${routePaths.USER_ORDERS}/`,
        element: <OrdersList />,
      },
      {
        path: routePaths.USER_ORDERS_DETAILS,
        element: <OrderDetails />,
      },
    ],
  },
  {
    path: routePaths.SETTINGS,
    element: (
      <PrivateRoute>
        <UserProfile />
      </PrivateRoute>
    ),
    children: [
      { path: `${routePaths.SETTINGS}/`, element: <UserProfileIndex /> },
      {
        path: routePaths.PRIVACY_INFORMATION,
        element: <PrivacyInformation />,
      },
      { path: routePaths.COOKIES, element: <Cookies /> },
      { path: routePaths.CHANGE_LANGUAGE, element: <ChangeLanguage /> },
      { path: routePaths.FEEDBACK_AND_HELP, element: <FeedbackAndHelp /> },
      { path: routePaths.YOUR_BRANCH, element: <YourBranch /> },
      { path: routePaths.CUSTOMER_SERVICE, element: <CustomerService /> },
    ],
  },
  { path: routePaths.NOT_FOUND, element: <NotFound /> },
];

export default routes;
