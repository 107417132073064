import styled from '@emotion/styled';
import colors from 'assets/colors';
import fontSizes from 'assets/fontSizes';
import fontWeights from 'assets/fontWeights';
import spacing from 'assets/spacing';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';

export const StyledHeading = styled(Heading)`
  margin-bottom: ${spacing.small};
`;
export const TextWrapper = styled.div`
  padding-bottom: ${spacing.small};
  border-bottom: 1px solid ${colors.primGrey_03};
  margin-bottom: ${spacing.small};
`;

export const TextAreaLabel = styled(Paragraph)`
  margin-bottom: ${spacing.tiny};
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: ${spacing.xxsmall};
  font-size: ${fontSizes.body16};
  font-family: inherit;
  line-height: 24px;
  font-weight: ${fontWeights.normal};
  letter-spacing: 0.5px;
  color: ${colors.compCharcoal_00};
`;

export const CharacterLeftParagraph = styled(Paragraph)`
  text-align: right;
  padding-top: ${spacing.tiny};
  padding-bottom: ${spacing.xsmall};
`;
