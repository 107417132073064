import { SyntheticEvent, useEffect, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import {
  RadioButtonWrapper,
  Input,
  OuterCircle,
  InnerCircleMotion,
  InnerCircleShadowMotion,
} from './styles';

type RadioButtonProps = {
  id: string;
  value: string;
  handleOnChange: (e: SyntheticEvent) => void;
  checked: boolean;
};

const RadioButton = ({ id, value, handleOnChange, checked }: RadioButtonProps) => {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return (
    <AnimatePresence initial={false}>
      <RadioButtonWrapper>
        <Input
          type="radio"
          data-testid={id}
          id={id}
          value={value}
          checked={checked}
          onChange={handleOnChange}
        />
        <span>
          <OuterCircle>
            {!firstRender.current && (
              <InnerCircleShadowMotion
                initial={{ scale: 0 }}
                animate={{ scale: checked ? 3 : 0, opacity: checked ? [0.6, 0.6, 0.6, 0] : 0 }}
                transition={{ duration: 0.35 }}
              />
            )}
            <InnerCircleMotion
              initial={{ scale: 0 }}
              animate={{ scale: checked ? 1 : 0 }}
              transition={{ duration: 0.2 }}
            />
          </OuterCircle>
        </span>
      </RadioButtonWrapper>
    </AnimatePresence>
  );
};

export default RadioButton;
