import { selectSelectedLocation } from 'state/locationsSlice';
import { createSlice, type PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import {
  excludeArticlesWithNoBookableRoutes,
  filterOutDeclaredDuplicates,
} from 'utils/articleUtil';
import { type RootState } from 'state/store';
import { type ArticleItemType } from 'types/article';
import { getArticles } from 'api/articles';
import { Country } from '@stenarecycling/customer-portal-types';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchArticles())`.
export const fetchArticles = createAsyncThunk(
  'articles/fetchArticles',
  async (
    props: { token: string; transactionId: string; partnerId: string; pickupAddressId?: string },
    payloadCreator,
  ) => getArticles(props, payloadCreator),
);

type ArticlesState = {
  availableArticles: ArticleItemType[];
  loading: boolean;
  errorLoading: string | null;
};

export const initialArticlesState: ArticlesState = {
  availableArticles: [],
  loading: false,
  errorLoading: null,
};

export const articlesSlice = createSlice({
  name: 'articles',
  initialState: initialArticlesState,
  reducers: {
    setWeight: (state, action: PayloadAction<{ id: string; weight: number }>) => {
      const { id, weight } = action.payload;
      const articleToBeUpdated = state.availableArticles.find((article) => article.id === id);

      if (articleToBeUpdated) {
        articleToBeUpdated.weight = weight;
      }
    },
    resetWeight: (state) => {
      state.availableArticles.forEach((article) => {
        article.weight = 0;
      });
    },
    setComment: (state, action: PayloadAction<{ id: string; comment: string | null }>) => {
      const { id, comment } = action.payload;
      const articleToBeUpdated = state.availableArticles.find((article) => article.id === id);

      if (articleToBeUpdated) {
        articleToBeUpdated.comment = comment;
      }
    },
    resetComment: (state) => {
      state.availableArticles.forEach((article) => {
        article.comment = '';
      });
    },
    decrementNumber: (state, action: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const id = action.payload;
      const articleToBeUpdated = state.availableArticles.find((article) => article.id === id);

      if (articleToBeUpdated) {
        articleToBeUpdated.numberOfItems--;
      }
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementNumber: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const articleToBeUpdated = state.availableArticles.find((article) => article.id === id);

      if (articleToBeUpdated) {
        articleToBeUpdated.numberOfItems++;
      }
    },
    resetNumberOfItems: (state) => {
      state.availableArticles.forEach((article) => {
        article.numberOfItems = 0;
      });
    },
  },
  // Add reducers for additional action types here, and handle loading state as needed
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticles.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchArticles.fulfilled, (state, { payload: articles }) => {
        state.availableArticles = articles;
        state.loading = false;
        state.errorLoading = null;
      })
      .addCase(fetchArticles.rejected, (state, { payload: errorMessage }) => {
        state.availableArticles = [];
        state.loading = false;
        state.errorLoading = errorMessage as string;
      });
  },
});

export const {
  setWeight,
  resetWeight,
  setComment,
  resetComment,
  decrementNumber,
  incrementNumber,
  resetNumberOfItems,
} = articlesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.articles.availableArticles)`
export const selectArticles = (state: RootState) => state.articles.availableArticles;

export const selectAvailableArticles = createSelector(
  [selectArticles, selectSelectedLocation],
  (availableArticles, selectedLocation) => {
    let filteredArticles = excludeArticlesWithNoBookableRoutes(availableArticles);

    if (selectedLocation?.country === Country.DENMARK) {
      filteredArticles = filterOutDeclaredDuplicates(filteredArticles);
    }

    return filteredArticles;
  },
);

export const selectSelectedArticles = createSelector(
  [selectAvailableArticles],
  (availableArticles) => availableArticles.filter((article) => article.numberOfItems > 0),
);

export const selectLoadingArticles = (state: RootState) => state.articles.loading;

export default articlesSlice.reducer;
