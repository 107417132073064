import Heading, { HeadingStyleType, HeadingType } from 'components/Heading';
import Paragraph, { ParagraphStyleType } from 'components/Paragraph';
import Dialog from 'components/Dialog';
import colors from 'assets/colors';
import AddToHomescreenIcon from 'assets/icons/AddToHomescreenIcon.svg';
import appLogo from 'assets/icons/app-icon-72x72.png';
import { useTranslation } from 'react-i18next';
import { useInstallPWADialog } from 'utils/hooks/useInstallPWADialog';
import { isSafari, isEdgeIos, isFireFoxIos, isOpera, isChromeIos } from 'utils/userAgentUtil';
import {
  HeaderWrapper,
  PlusIcon,
  StenaLogoWrapped,
  ShareIcon,
  LongLine,
  Instruction,
} from './styles';

const New_installPWADialog = ({ ...props }) => {
  const { isOpen, close: onClose } = useInstallPWADialog();

  const safari = isSafari();
  const edge = isEdgeIos();
  const chrome = isChromeIos();
  const opera = isOpera();
  const firefox = isFireFoxIos();

  const isSafariNotEdgeOrOther = safari && !edge && !chrome && !opera && !firefox;

  const { t } = useTranslation();

  if (!isSafariNotEdgeOrOther) {
    return (
      <Dialog {...{ isOpen, onClose }} {...props}>
        <PlusIcon>
          <img src={AddToHomescreenIcon} alt="share on iphone" />
        </PlusIcon>
        <Heading styleType={HeadingStyleType.Heading4} headingType={HeadingType.H2} center>
          {t('installPWADialog.heading')}
        </Heading>
        <Paragraph styleType={ParagraphStyleType.body16} fontColor={colors.black} center>
          {t('installPWADialog.redirect1')}
        </Paragraph>
      </Dialog>
    );
  }

  return (
    <Dialog positionDown {...{ isOpen, onClose }} {...props}>
      <HeaderWrapper>
        <StenaLogoWrapped>
          <img src={appLogo} alt="Stena Recycling logo" width="40px" />
        </StenaLogoWrapped>
        <Paragraph styleType={ParagraphStyleType.body18} fontColor={colors.black} bold>
          {t('installPWADialog.heading')}
        </Paragraph>
      </HeaderWrapper>
      <LongLine />
      <Paragraph styleType={ParagraphStyleType.body16} fontColor={colors.black}>
        {t('installPWADialog.info')}
      </Paragraph>
      <Instruction styleType={ParagraphStyleType.body16} fontColor={colors.black}>
        1. {t('installPWADialog.instruction1')}
        <ShareIcon />
      </Instruction>
      <Paragraph styleType={ParagraphStyleType.body16} fontColor={colors.black}>
        2. {t('installPWADialog.instruction2')}{' '}
        <strong>{t('installPWADialog.instruction3')}</strong>
      </Paragraph>
      <Paragraph styleType={ParagraphStyleType.body16} fontColor={colors.black}>
        3. {t('installPWADialog.instruction4')}{' '}
        <strong>{t('installPWADialog.instruction5')}</strong> {t('installPWADialog.instruction6')}
      </Paragraph>
    </Dialog>
  );
};

export default New_installPWADialog;
