export enum routePaths {
  INTRODUCTION = '/',
  LOGIN = '/login',
  PLACE_ORDER = '/place-order',
  CONFIRMATION = '/confirmation',
  USER_ORDERS = '/orders',
  USER_ORDERS_DETAILS = '/orders/:orderInfo',
  SETTINGS = '/settings',
  PRIVACY_INFORMATION = '/settings/privacy-information',
  COOKIES = '/settings/cookies',
  CHANGE_LANGUAGE = '/settings/change-language',
  FEEDBACK_AND_HELP = '/settings/feedback-and-help',
  YOUR_BRANCH = '/settings/your-branch',
  CUSTOMER_SERVICE = '/settings/customer-service',
  NOT_FOUND = '*',
}
