const baseColors = {
  stenaBlue: '#0074BA',
  stenaOrange: '#D27429',
  black: '#000000',
  white: '#FFFFFF',
};

const primaryColors = {
  primGreen_00: '#6A8171',
  primGreen_01: '#93A498',
  primGreen_02: '#BFCAC2',
  primGreen_03: '#E5EBE6',
  primGrey_00: '#62636A',
  primGrey_01: '#8C8A8F',
  primGrey_02: '#C7C5C9',
  primGrey_03: '#EFEFEF',
  primBlue_00: '#5A6C81',
  primBlue_01: '#7C95AB',
  primBlue_02: '#B2BFCC',
  primBlue_03: '#E5EBF0',
};

const complementaryColors = {
  compOrange_00: '#B0715F',
  compOrange_01: '#C18F81',
  compOrange_02: '#D1ADA3',
  compOrange_03: '#E2CBC5',
  compBeige_00: '#C5BAAC',
  compBeige_01: '#DBD3C8',
  compBeige_02: '#EBE7E0',
  compBeige_03: '#F2F1ED',
  compCharcoal_00: '#3F4347',
  compCharcoal_01: '#7C95AB',
  compCharcoal_02: '#B2BFCC',
  compCharcoal_03: '#E5EBF0',
  compBlue_00: '#3B96C4',
  compBlue_01: '#7C95AB',
  compBlue_02: '#B2BFCC',
  compBlue_03: '#E5EBF0',
};

const semanticColors = {
  info_00: '#3B96C4',
  info_03: '#E4F4FB',
  success_00: '#98B347',
  success_03: '#E8F3D3',
  warning_00: '#E0B400',
  warning_03: '#FEF4C8',
  danger_00: '#DE5C45',
  danger_03: '#FFF0EB',
};

const otherColors = {
  chartOrange: '#C68D6C',
  transparentGray: '#b2bfcc66',
  beige: '#f7f5f3',
};

const colors = {
  ...baseColors,
  ...primaryColors,
  ...complementaryColors,
  ...semanticColors,
  ...otherColors,
};

export default colors;
