import styled from '@emotion/styled';
import colors from 'assets/colors';
import { motion } from 'framer-motion';

export const CircleMotion = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: ${colors.compBeige_03};
`;
