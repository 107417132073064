import styled from '@emotion/styled';
import spacing from 'assets/spacing';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';

export const OrderFailedWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const TopPart = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledHeading = styled(Heading)`
  margin-top: ${spacing.mediumLow};
  margin-bottom: 0;
`;

export const StyledParagraph = styled(Paragraph)`
  margin-top: ${spacing.xxsmall};
`;

export const BottomTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${spacing.mediumHigh};
  padding: 0 ${spacing.small};
`;
