import styled from '@emotion/styled';
import colors from 'assets/colors';
import spacing from 'assets/spacing';
import { motion } from 'framer-motion';

export const RadioButtonWrapper = styled.div`
  width: ${spacing.mediumLow};
  height: ${spacing.mediumLow};
  margin-right: ${spacing.xsmall};
`;

export const Input = styled.input`
  opacity: 0;
  position: fixed;
  width: 0;

  &:focus-visible + span {
    outline: ${colors.primGreen_00} auto 1px;
  }
`;

export const OuterCircle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  border: 2px solid ${colors.primGreen_00};
`;

export const InnerCircleShadowMotion = styled(motion.div)`
  position: absolute;
  height: ${spacing.xsmall};
  width: ${spacing.xsmall};
  border-radius: 100%;
  background-color: ${colors.primGreen_03};
  z-index: 1;
`;

export const InnerCircleMotion = styled(motion.div)`
  height: ${spacing.xsmall};
  width: ${spacing.xsmall};
  border-radius: 100%;
  background-color: ${colors.primGreen_00};
  z-index: 2;
`;
