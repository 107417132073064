const fontSizes = {
  hero: '4rem',
  heading1: '3rem',
  heading2: '2.5rem',
  heading3: '2rem',
  heading4: '1.5rem',
  heading5: '1.25rem',
  body18: '1.125rem',
  body16: '1rem',
  body14: '0.875rem',
  body12: '0.75rem',
};

export default fontSizes;
