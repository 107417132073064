import { motion } from 'framer-motion';
import MenuButtonWrapper from './styles';
import Paragraph, { ParagraphStyleType } from 'components/Paragraph';
import colors from 'assets/colors';

export type MenuButtonProps = {
  to: string;
  iconUrl: string;
  text: string;
  isActive?: boolean;
  state?: object;
};

function MenuButton({ to, iconUrl, text = '', isActive, state }: MenuButtonProps) {
  return (
    <MenuButtonWrapper {...{ to, isActive, state }}>
      <motion.img
        className={isActive ? 'active' : ''}
        src={iconUrl}
        alt={text}
        transition={{ duration: 0.2, times: [0, 0.7, 1] }}
        animate={{ scale: isActive ? [0.97, 1.03, 1] : 1 }}
      />
      <Paragraph
        styleType={ParagraphStyleType.body12}
        fontColor={isActive ? colors.compBlue_00 : colors.primGrey_00}
      >
        {text}
      </Paragraph>
    </MenuButtonWrapper>
  );
}

export default MenuButton;
