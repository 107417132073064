import styled from '@emotion/styled';
import breakpoints from 'assets/breakpoints';

export const ItemDescription = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: ${breakpoints.tablet}) {
    justify-content: flex-start;
  }
`;
