import { motion } from 'framer-motion';
import { CircleMotion } from './styles';

const ContainerIcon = () => {
  const circle = {
    circle: {
      scale: [0.3, 1],
      transition: {
        delayChildren: 0.3,
        staggerChildren: -0.1,
        duration: 0.5,
        type: 'spring',
      },
    },
  };
  const container = {
    circle: { rotate: [0, 10, -8, 5, -3, 0], transition: { duration: 0.8 } },
  };

  return (
    <CircleMotion initial={{ scale: 0.3 }} variants={circle} animate="circle">
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          variants={container}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2338 12.4326L13.0889 12.4399C12.5632 12.4926 12.1024 12.8299 11.8961 13.3243L10.856 15.8271L8.35425 15.8276C7.52178 15.8276 6.89277 16.5798 7.04128 17.3989L12.1543 45.5959C12.2898 46.3503 12.9494 46.8996 13.7168 46.8996H50.3228C51.0899 46.8996 51.7487 46.3506 51.8853 45.5956L56.9982 17.3989L57.0153 17.2703C57.0799 16.5028 56.4735 15.8276 55.6853 15.8276L53.183 15.8271L52.1433 13.3253C51.9203 12.7859 51.3906 12.4326 50.8057 12.4326H13.2338ZM50.771 13.9321L51.9902 16.8654L52.0409 16.9656C52.1752 17.188 52.4176 17.3276 52.6827 17.3276L55.486 17.3271L50.4093 45.3283C50.4019 45.369 50.3652 45.3996 50.3227 45.3996H13.7168L13.6863 45.3942C13.6577 45.3838 13.6358 45.3592 13.6304 45.3295L8.553 17.3271L11.3568 17.3276L11.4688 17.3192C11.7257 17.2805 11.9476 17.1102 12.0494 16.8653L13.267 13.9321H50.771ZM50.2321 16.5805C50.2321 16.1663 49.8963 15.8305 49.4821 15.8305H14.5571L14.4553 15.8373C14.0893 15.887 13.8071 16.2008 13.8071 16.5805C13.8071 16.9947 14.1429 17.3305 14.5571 17.3305H49.4821L49.5839 17.3236C49.9499 17.274 50.2321 16.9602 50.2321 16.5805ZM16.4184 51.7837C16.4184 49.716 18.0951 48.0397 20.1629 48.0397C22.2306 48.0397 23.9074 49.716 23.9074 51.7837C23.9074 53.8517 22.2307 55.5282 20.1629 55.5282C18.095 55.5282 16.4184 53.8517 16.4184 51.7837ZM22.4074 51.7837C22.4074 50.5445 21.4023 49.5397 20.1629 49.5397C18.9234 49.5397 17.9184 50.5445 17.9184 51.7837C17.9184 53.0233 18.9234 54.0282 20.1629 54.0282C21.4023 54.0282 22.4074 53.0233 22.4074 51.7837ZM43.8766 48.0397C41.8088 48.0397 40.1321 49.716 40.1321 51.7837C40.1321 53.8517 41.8087 55.5282 43.8766 55.5282C45.9444 55.5282 47.621 53.8517 47.621 51.7837C47.621 49.716 45.9443 48.0397 43.8766 48.0397ZM43.8766 49.5397C45.116 49.5397 46.121 50.5445 46.121 51.7837C46.121 53.0233 45.116 54.0282 43.8766 54.0282C42.6371 54.0282 41.6321 53.0233 41.6321 51.7837C41.6321 50.5445 42.6371 49.5397 43.8766 49.5397Z"
          fill="#3F4347"
        />
      </svg>
    </CircleMotion>
  );
};

export default ContainerIcon;
