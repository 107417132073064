import { type ReactNode } from 'react';
import SubmitButtonWrapper, { StyledSubmitButton } from './styles';
import spacing from 'assets/spacing';
import isPropValid from '@emotion/is-prop-valid';
import { MotionConfig } from 'framer-motion';

type SubmitButtonProps = {
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  sticky?: boolean;
};

const SubmitButton = ({
  children,
  onClick,
  disabled = false,
  sticky = true,
  ...rest
}: SubmitButtonProps) => {
  const variants = {
    sticky: { bottom: `calc(${spacing.bottomMenuHeight} + ${spacing.small})` },
    nonSticky: { bottom: 'calc(0px + 0px)' }, // need to use calc() here because framer-motion can't calculate animation from 0 to calc(..)
  };

  //Fix for bad types in used lib
  const typeFix = {} as {
    onPointerLeaveCapture: () => void;
    onPointerEnterCapture: () => void;
    placeholder: string;
  };

  return (
    <MotionConfig isValidProp={isPropValid}>
      <SubmitButtonWrapper
        animate={sticky ? 'sticky' : 'nonSticky'}
        variants={variants}
        transition={{ duration: 0.3 }}
        sticky={sticky}
        {...rest}
        data-testid="sticky-button-wrapper"
      >
        <StyledSubmitButton onClick={onClick} disabled={disabled} {...typeFix}>
          {children}
        </StyledSubmitButton>
      </SubmitButtonWrapper>
    </MotionConfig>
  );
};

export default SubmitButton;
