import LinkButtonWrapper from './styles';

type LinkButtonProps = {
  to: string;
  children: React.ReactNode;
  isActive?: boolean;
  upperCase?: boolean;
  bgColor?: string;
  textColor?: string;
};

function LinkButton(props: LinkButtonProps) {
  const { to, children, ...rest } = props;

  return (
    <LinkButtonWrapper to={to} {...rest}>
      {children}
    </LinkButtonWrapper>
  );
}

export default LinkButton;
