import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getViewName } from 'utils/routeUtil';
import i18next from 'i18next';

const useViewName = () => {
  const location = useLocation();
  const { ready, t } = useTranslation();
  const [viewName, setViewName] = useState('');
  const currentLanguage = i18next.language;

  useEffect(() => {
    if (!ready) return;
    setViewName(getViewName(location.pathname, t));
  }, [location, currentLanguage, ready]);

  return viewName;
};

export default useViewName;
