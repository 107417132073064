import { useTranslation } from 'react-i18next';
import { OrderItemType } from 'types/order';
import colors from 'assets/colors';
import { HeadingStyleType, HeadingType } from 'components/Heading';
import { ParagraphStyleType } from 'components/Paragraph';
import { BlockWrapper, BlockTitle, Column, Label, Value } from '../styles';
import { ItemDescription } from './styles';

export type ArticlesBlockProps = {
  order?: OrderItemType | null;
};

const ArticlesBlock = ({ order }: ArticlesBlockProps) => {
  const { t } = useTranslation();
  const quantity = order?.numberOfItems === -1 ? '-' : order?.numberOfItems;

  return (
    <BlockWrapper>
      <BlockTitle headingType={HeadingType.H2} styleType={HeadingStyleType.Heading7} upperCase>
        {t('orderDetails.articles.title')}
      </BlockTitle>
      <ItemDescription>
        <Column>
          <Label styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_00} upperCase>
            {t('orderDetails.article.name')}
          </Label>
          <Value styleType={ParagraphStyleType.body16} data-testid="order-article-name">
            {order?.title || 'Övrigt'}
          </Value>
        </Column>
        <Column>
          <Label styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_00} upperCase>
            {t('orderDetails.quantity')}
          </Label>
          <Value styleType={ParagraphStyleType.body16} data-testid="order-quantity">
            {quantity}
          </Value>
        </Column>
      </ItemDescription>
      <Column>
        <Label styleType={ParagraphStyleType.body14} fontColor={colors.primGrey_00} upperCase>
          {t('orderDetails.container')}
        </Label>
        <Value styleType={ParagraphStyleType.body16}>{order?.description}</Value>
      </Column>
    </BlockWrapper>
  );
};

export default ArticlesBlock;
