import styled from '@emotion/styled';
import { Button } from '@stenametall/component-library';
import spacing from 'assets/spacing';
import { motion } from 'framer-motion';

type SubmitButtonType = { sticky: boolean };
const SubmitButtonWrapper = styled(motion.div)<SubmitButtonType>`
  position: sticky;
  display: flex;
  justify-content: center;
  margin: 0 ${spacing.small};
`;

export const StyledSubmitButton = styled(Button)`
  max-width: calc(${spacing.maxWidth} / 2);
  box-shadow: 0px 0px 35px 25px #ffff;
`;

export default SubmitButtonWrapper;
