import { DialogOverlay, CloseIconWrapper, MotionDialogWrapper } from './styles';
import closeIcon from 'assets/icons/x.svg';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import isPropValid from '@emotion/is-prop-valid';
import { ReactNode } from 'react';

type DialogProps = {
  onClose: () => void;
  isOpen: boolean;
  fullscreen?: boolean;
  children: ReactNode;
  positionDown?: boolean;
};

function Dialog({
  onClose,
  isOpen,
  children,
  fullscreen = false,
  positionDown = false,
  ...rest
}: DialogProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <DialogOverlay fullscreen={fullscreen} positionDown={positionDown} {...rest}>
          <MotionConfig isValidProp={isPropValid}>
            <MotionDialogWrapper
              fullscreen={fullscreen}
              positionDown={positionDown}
              initial={{ translateY: '100vh' }}
              animate={{ translateY: '0vh' }}
              exit={{ translateY: '100vh' }}
              transition={{ duration: 0.2 }}
            >
              <CloseIconWrapper onClick={onClose} positionDown={positionDown}>
                <img src={closeIcon} alt="close icon" />
              </CloseIconWrapper>
              {children}
            </MotionDialogWrapper>
          </MotionConfig>
        </DialogOverlay>
      )}
    </AnimatePresence>
  );
}

export default Dialog;
