import { useTranslation } from 'react-i18next';
import Dialog from 'components/Dialog';
import Paragraph, { ParagraphStyleType } from 'components/Paragraph';
import {
  NoAccountWrapper,
  NoAccountTitleParagraph,
  NoAccountParagraph,
  CountryLinkList,
  StyledGlobeIcon,
  BlockATag,
} from './styles';
import colors from 'assets/colors';

export type NoAccountProps = {
  onClose: () => void;
  isOpen: boolean;
};

const NoAccount = (props: NoAccountProps) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();

  return (
    <NoAccountWrapper>
      <Dialog isOpen={isOpen} onClose={onClose} positionDown>
        <NoAccountTitleParagraph
          styleType={ParagraphStyleType.body18}
          fontColor={colors.black}
          bold
        >
          {t('introduction.noAccount.dialog.title')}
        </NoAccountTitleParagraph>
        <CountryLinkList>
          <NoAccountParagraph styleType={ParagraphStyleType.body16} fontColor={colors.black}>
            {t('introduction.noAccount.dialog.text')}
          </NoAccountParagraph>
          <BlockATag
            target="_blank"
            href="https://www.stenarecycling.dk/ydelser/vores-services-2020/kundeportal/"
          >
            <Paragraph
              styleType={ParagraphStyleType.body16}
              fontColor={colors.black}
              bold
              upperCase
            >
              Denmark
            </Paragraph>
            <StyledGlobeIcon />
          </BlockATag>
          <BlockATag
            target="_blank"
            href="https://www.stenarecycling.fi/palvelut/kierratyspalvelut/asiakasportaali/"
          >
            <Paragraph
              styleType={ParagraphStyleType.body16}
              bold
              upperCase
              fontColor={colors.black}
            >
              Finland
            </Paragraph>
            <StyledGlobeIcon />
          </BlockATag>
          <BlockATag
            target="_blank"
            href="https://www.stenarecycling.no/tjenester/avfallslosninger/kundeportal/"
          >
            <Paragraph
              styleType={ParagraphStyleType.body16}
              bold
              upperCase
              fontColor={colors.black}
            >
              Norway
            </Paragraph>
            <StyledGlobeIcon />
          </BlockATag>
          <BlockATag
            target="_blank"
            href="https://www.stenarecycling.se/tjanster/atervinningstjanster/kundportal/"
          >
            <Paragraph
              styleType={ParagraphStyleType.body16}
              bold
              upperCase
              fontColor={colors.black}
            >
              Sweden
            </Paragraph>
            <StyledGlobeIcon />
          </BlockATag>
        </CountryLinkList>
      </Dialog>
    </NoAccountWrapper>
  );
};

export default NoAccount;
