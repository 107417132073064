import { type SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type Location } from '@stenarecycling/customer-portal-types';
import { Country } from 'types/location';
import { type ArticleItemType, type RouteInfo } from 'types/article';
import { useDialog } from 'utils/hooks/useDialog';
import { getNextAvailableRoutes } from 'utils/articleUtil';
import { Controller, ControllerIcons } from '@stenametall/component-library';
import { ParagraphStyleType } from 'components/Paragraph';
import ATag from 'components/ATag';
import ArticleCommentDialog from '../ArticleCommentDialog';
import WeightInputItem from './WeightInput';
import DoubleBookingWarning from './DoubleBookingWarning';
import ArticleNotification from './ArticleNotification';
import ArticleWrapper, {
  Content,
  Title,
  Description,
  ButtonGroup,
  NumberOfItems,
  AddComment,
  TopBorder,
} from './styles';

export type ArticleItemProps = {
  decrement: (id: string) => void;
  increment: (id: string) => void;
  handleOnSaveComment: (id: string, comment: string | null) => void;
  handleOnSaveWeight: (id: string, weight: number) => void;
  showDoubleBookingWarning?: boolean;
  selectedLocation: Location | null;
} & ArticleItemType;

const ArticleItem = (props: ArticleItemProps) => {
  const {
    id,
    title,
    description,
    numberOfItems,
    maxNumberOfItems,
    comment,
    hazardousWaste,
    selectedLocation,
    decrement,
    increment,
    handleOnSaveComment,
    handleOnSaveWeight,
    showDoubleBookingWarning,
    routesInfo,
  } = props;

  const { t } = useTranslation();
  const { isOpen, open, close: onClose } = useDialog();
  const country: Country | undefined = selectedLocation?.country;

  const handleCommentOnClick = (e: SyntheticEvent) => {
    e.preventDefault();
    open();
  };

  const [nextBookablePickupDate, setNextBookablePickupDate] = useState<string | null>(null);
  const [nextScheduledPickupDate, setNextScheduledPickupDate] = useState<string | null>(null);
  const [countryMaxAmount, setCountryMaxAmount] = useState<number>(99);

  const getNextAvailablePickupDates = (routesInfo?: RouteInfo[]) => {
    if (routesInfo != null) {
      const { scheduled, bookable } = getNextAvailableRoutes(routesInfo);

      setNextScheduledPickupDate(scheduled?.pickupDate || null);
      setNextBookablePickupDate(bookable?.pickupDate || null);
    }
  };

  const commentText = comment ? t('placeOrder.comments.edit') : t('placeOrder.comments.add');

  useEffect(() => {
    if (country === Country.FINLAND) {
      setCountryMaxAmount(1);
    }
    if (country === Country.NORWAY) {
      setCountryMaxAmount(50);
    }
  }, [country]);

  useEffect(() => {
    getNextAvailablePickupDates(routesInfo);
  }, [routesInfo]);

  return (
    <>
      <ArticleWrapper {...props}>
        <Content>
          <Title styleType={ParagraphStyleType.body16}>{title}</Title>
          {selectedLocation?.country === Country.SWEDEN &&
          maxNumberOfItems &&
          maxNumberOfItems > 1 ? (
            <Description styleType={ParagraphStyleType.body14}>
              {maxNumberOfItems} x {description}
            </Description>
          ) : (
            <Description styleType={ParagraphStyleType.body14}>{description}</Description>
          )}
        </Content>

        <ButtonGroup>
          <Controller
            title="decrement"
            data-testid="decrement-button"
            icon={ControllerIcons.Minus}
            onClick={() => {
              decrement(id);
            }}
            disabled={numberOfItems <= 0}
          />
          <NumberOfItems data-testid="number-of-items">{numberOfItems}</NumberOfItems>
          {selectedLocation?.country === Country.SWEDEN && maxNumberOfItems ? (
            <Controller
              title="increment"
              data-testid="increment-button"
              icon={ControllerIcons.Plus}
              onClick={() => {
                increment(id);
              }}
              disabled={numberOfItems >= maxNumberOfItems}
            />
          ) : (
            <Controller
              title="increment"
              data-testid="increment-button"
              icon={ControllerIcons.Plus}
              onClick={() => {
                increment(id);
              }}
              disabled={numberOfItems >= countryMaxAmount}
            />
          )}
        </ButtonGroup>
      </ArticleWrapper>

      {numberOfItems > 0 && (
        <>
          {showDoubleBookingWarning && <DoubleBookingWarning />}
          {country === Country.SWEDEN && hazardousWaste && (
            <WeightInputItem {...{ id, handleOnSaveWeight, selectedLocation }} />
          )}

          {nextBookablePickupDate && (
            <ArticleNotification
              message={`${t('placeOrder.article.possiblePickup')}: ${nextBookablePickupDate}`}
            />
          )}
          {nextScheduledPickupDate && (
            <ArticleNotification
              type="warning"
              message={`${t('placeOrder.article.scheduledPickup')}: ${nextScheduledPickupDate}`}
            />
          )}

          <AddComment>
            <TopBorder />
            <ATag onClick={handleCommentOnClick}>{commentText}</ATag>
          </AddComment>
        </>
      )}

      <ArticleCommentDialog
        {...{ id, title, description, comment, isOpen, onClose, handleOnSaveComment }}
      />
    </>
  );
};

export default ArticleItem;
