import { motion } from 'framer-motion';
import { CircleMotion } from './styles';

const ConfirmationFailedIcon = () => {
  const circle = {
    animation: {
      scale: [0.3, 1],
      transition: {
        when: 'beforeChildren',
        duration: 0.5,
        type: 'spring',
        staggerChildren: 0.2,
      },
    },
  };
  const line1 = {
    animation: { pathLength: [0, 1], transition: { duration: 0.2 }, opacity: [0, 1, 1, 1] },
  };

  return (
    <CircleMotion variants={circle} animate="animation">
      <svg
        width="48"
        height="48"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          id="Path_2"
          d="M5.5 5.5L18.5 18.5"
          stroke="#3F4347"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          variants={line1}
          initial={{ opacity: 0, pathLength: 0 }}
        />
        <motion.path
          id="Path"
          d="M18.5 5.5L5.5 18.5"
          stroke="#3F4347"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          variants={line1}
          initial={{ opacity: 0, pathLength: 0 }}
        />
      </svg>
    </CircleMotion>
  );
};

export default ConfirmationFailedIcon;
