import LeftArrowIcon from 'assets/icons/arrow-left.svg?react';
import { LinkProps } from 'react-router-dom';
import BackButtonWrapper from './styles';

const BackButton = (props: LinkProps & React.RefAttributes<HTMLAnchorElement>) => {
  return (
    <BackButtonWrapper {...props} data-testid="BackButton">
      <LeftArrowIcon />
    </BackButtonWrapper>
  );
};

export default BackButton;
