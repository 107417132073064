import StyledAnchorButton from './styles';
import { ReactNode } from 'react';

export type AnchorButtonProps = {
  children: ReactNode;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  disabled?: boolean;
  upperCase?: boolean;
  bgColor?: string;
  target?: string;
};
function AnchorButton({
  children,
  href = '',
  onClick = () => true,
  disabled,
  upperCase = false,
  bgColor = '',
  target,
}: AnchorButtonProps) {
  return (
    <StyledAnchorButton
      href={href}
      onClick={!disabled ? onClick : (e) => e.preventDefault()}
      target={target ? target : '_self'}
      upperCase={upperCase}
      bgColor={bgColor}
    >
      {children}
    </StyledAnchorButton>
  );
}

export default AnchorButton;
