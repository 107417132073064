import { env } from '../../../../packages/helpers/env';
import { isLocalhost } from './isLocalhost';

export const MODE = env('MODE');
export const APP_VERSION = env('PORTAL_VERSION', '1688392023');

const PORTAL_DISABLE_REDUX_LOGGER = env('PORTAL_DISABLE_REDUX_LOGGER', 'false');
const PORTAL_DISABLE_MIRAGE_LOGGER = env('PORTAL_DISABLE_MIRAGE_LOGGER', 'false');

export const DISABLE_REDUX_LOGGER = PORTAL_DISABLE_REDUX_LOGGER === 'true';
export const DISABLE_MIRAGE_LOGGER = PORTAL_DISABLE_MIRAGE_LOGGER === 'true';

const STAGE = env('PORTAL_ENV', 'production');
const API_URL = env('PORTAL_RECYCLING_API_URL');

const { hostname } = window.location;

export const APP_ENV = (() => {
  if (hostname.endsWith('test.stenarecycling.cloud') || MODE === 'test') {
    return 'test';
  }
  if (hostname.endsWith('dev.stenarecycling.cloud')) {
    return 'development';
  }
  if (isLocalhost || (STAGE === 'local' && MODE === 'development')) {
    return 'local';
  }

  //In all other cases return the production URL. However we have in theory two public ones?...
  return 'production';
})();

const newApiUrl = () => {
  //const local = 'http://localhost:3001';
  const development = 'https://api.dev.stenarecycling.cloud/stenarecycling';
  const test = 'https://api.test.stenarecycling.cloud/stenarecycling';
  const production = 'https://api.stenarecycling.com/stenarecycling';

  if (APP_ENV === 'local') {
    return development;
  }

  if (APP_ENV === 'development') {
    return development;
  }

  if (APP_ENV === 'test') {
    return test;
  }

  return production;
};

const portalUrl = () => {
  //const local = 'http://localhost:3001';
  const development = 'https://portal.dev.stenarecycling.cloud';
  const test = 'https://portal.test.stenarecycling.cloud';
  const production = 'https://portal.stenarecycling.com';

  if (APP_ENV === 'local') {
    return development;
  }

  if (APP_ENV === 'development') {
    return development;
  }

  if (APP_ENV === 'test') {
    return test;
  }

  return production;
};

export const environment = {
  intercomAppId: 'f757gz5q',
  gtm: 'GTM-56L9GXQ',
  newApi: {
    url: API_URL ?? newApiUrl(),
    path: {
      orders: '/v2/orders',
      articles: '/v2/articles',
      partners: '/v2/partners', //Header newlogin = true
      branchContact: '/v2/branch-contact',
      mail: '/mail',
    },
  },
  portalUrl: portalUrl(),
};
