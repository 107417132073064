import styled from '@emotion/styled';
import colors from 'assets/colors';
import spacing from 'assets/spacing';

const AppContainerWrapper = styled.div`
  height: 100%;
  width: 100vw;
  background-color: ${colors.white};
  max-width: ${spacing.maxWidth};
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default AppContainerWrapper;
