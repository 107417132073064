import { type HTMLMotionProps, MotionConfig } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { type ReactNode, useEffect, useRef } from 'react';
import useHideBottomMenu from 'utils/hooks/useHideBottomMenu';
import useViewName from 'utils/hooks/useViewName';
import Header from './Header';
import { PageWrapper } from './styles';
import isPropValid from '@emotion/is-prop-valid';
import { useEventTracking } from 'core/src/lib/hooks/useEventTracking/useEventTracking';

type PageProps = {
  children: ReactNode;
  leftActionButton?: ReactNode;
  title?: string;
  showHeader?: boolean;
  showHeaderLogo?: boolean;
  trackedTitle?: string;
} & HTMLMotionProps<'div'>;

const Page = ({
  children,
  leftActionButton,
  title,
  showHeader = true,
  showHeaderLogo = false,
  trackedTitle,
  ...rest
}: PageProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const hideMenu = useHideBottomMenu();
  const viewName = useViewName();
  const { trackPageView } = useEventTracking();

  const displayedTitle = title ? title : viewName;

  if (displayedTitle) {
    document.title = displayedTitle;
  }
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (trackedTitle) {
      trackPageView(trackedTitle);
    }
  }, [trackedTitle, trackPageView]);

  return (
    <MotionConfig isValidProp={isPropValid}>
      <PageWrapper
        data-testid="page"
        ref={ref}
        onAnimationStart={() => {
          ref.current?.classList.remove('resetTransform');

          const MainWrapper = document.getElementById('MainWrapper') as HTMLDivElement;

          MainWrapper?.setAttribute('style', 'overflow-x:hidden;');
        }}
        onAnimationComplete={() => {
          //resets the location.state because location.state.from will persist a page reload.
          navigate(location.pathname, { replace: true, state: null });

          //overrides the transform:translate() left by framer-motion because it messes with position:fixed on the header
          ref.current?.classList.add('resetTransform');

          //overflow:hidden breaks position:sticky so we need to add it and remove it during animations
          const MainWrapper = document.getElementById('MainWrapper') as HTMLDivElement;

          MainWrapper?.setAttribute('style', 'overflow-x:initial;');
        }}
        showHeader={showHeader}
        hideMenu={hideMenu}
        {...rest}
      >
        {showHeader && (
          <Header
            title={displayedTitle}
            showHeaderLogo={showHeaderLogo}
            leftActionButton={leftActionButton}
          />
        )}
        {children}
      </PageWrapper>
    </MotionConfig>
  );
};

export default Page;
