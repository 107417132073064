import axios from 'axios';
import { environment } from 'helper/environment';

export type IDefaultMail = {
  toEmail: string;
  subject?: string;
  message?: string;
};

export const sendMail = async (mail: IDefaultMail) => {
  return axios
    .post(`${environment.newApi.url}/mail`, mail)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};
