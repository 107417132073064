import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { routePaths } from 'routes/routePaths';
import colors from 'assets/colors';
import { type ArticleItemType } from 'types/article';
import { selectConfirmationArticles, selectConfirmationLocation } from 'state/confirmationSlice';
import { HeadingType, HeadingStyleType } from 'components/Heading';
import Paragraph, { ParagraphStyleType } from 'components/Paragraph';
import {
  OrderSuccessWrapper,
  StyledParagraph,
  StyledHeading,
  OrderSummaryWrapper,
  OrderSuccessHeadBackground,
  StyledHeading2,
} from './styles';
import ConfirmationListItem from './ConfirmationListItem';
import ConfirmationCheckIcon from './ConfirmationCheckIcon';
import { OPV2Message } from 'components/OPV2Prompt/OPV2Message';

const OrderSuccess = () => {
  const { t } = useTranslation();
  const articles = useSelector(selectConfirmationArticles);
  const location = useSelector(selectConfirmationLocation);

  const navigate = useNavigate();

  useEffect(() => {
    if (articles.length === 0) {
      navigate(routePaths.PLACE_ORDER, { replace: true });
    }
  }, [articles, navigate]);

  const sortedArticles: ArticleItemType[] = [...articles].sort((a, b) =>
    a.title.localeCompare(b.title),
  );

  const { id, name, address } = location ?? {};
  const { street, city } = address ?? {};

  return (
    <OrderSuccessWrapper>
      <OrderSuccessHeadBackground>
        <ConfirmationCheckIcon />

        <StyledHeading
          headingType={HeadingType.H1}
          styleType={HeadingStyleType.Heading4}
          fontColor={colors.black}
          center
        >
          {t('confirmation.success.title')}
        </StyledHeading>
        <StyledParagraph styleType={ParagraphStyleType.body18} fontColor={colors.black} center>
          {t('confirmation.success.message')}
        </StyledParagraph>
      </OrderSuccessHeadBackground>
      <OPV2Message />
      <OrderSummaryWrapper>
        <StyledHeading2
          styleType={HeadingStyleType.Heading7}
          fontColor={colors.primGrey_00}
          upperCase
        >
          {t('confirmation.success.location')}
        </StyledHeading2>
        <Paragraph styleType={ParagraphStyleType.body16} fontColor={colors.black}>
          {`${street}, ${city}`}
        </Paragraph>
        <Paragraph styleType={ParagraphStyleType.body16} fontColor={colors.black}>
          {`(${id}) ${name}`}
        </Paragraph>
        <StyledHeading2
          styleType={HeadingStyleType.Heading7}
          fontColor={colors.primGrey_00}
          upperCase
        >
          {t('confirmation.success.article')}
        </StyledHeading2>
        {sortedArticles?.map((article) => (
          <ConfirmationListItem
            data-testid="confirmation-list-item"
            key={article.id}
            article={article}
          />
        ))}
      </OrderSummaryWrapper>
    </OrderSuccessWrapper>
  );
};

export default OrderSuccess;
