import styled from '@emotion/styled';
import spacing from 'assets/spacing';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';

export const NoLocationsAvailableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 ${spacing.small};
  height: 100%;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledHeading = styled(Heading)`
  margin-top: ${spacing.mediumLow};
  margin-bottom: 0;
`;

export const StyledParagraph = styled(Paragraph)`
  margin-top: ${spacing.xxsmall};
  margin-bottom: ${spacing.xlarge};
`;
