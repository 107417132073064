import styled from '@emotion/styled';
import colors from 'assets/colors';
import fontSizes from 'assets/fontSizes';
import spacing from 'assets/spacing';
import Heading from 'components/Heading';

const LocationWrapper = styled.div`
  padding: ${spacing.mediumLow} ${spacing.small};
  border-bottom: 1px solid ${colors.primGrey_03};
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: ${spacing.xsmall};
`;

export const LinkChangeLocation = styled.a`
  display: block;
  margin-top: ${spacing.xsmall};
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.compBlue_00};
  font-size: ${fontSizes.body16};
  letter-spacing: 0.5px;
  line-height: 24px;
`;

export default LocationWrapper;
