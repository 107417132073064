import { useTranslation } from 'react-i18next';
import Page from 'components/Page';
import ErrorMessage from 'components/ErrorMessage';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Page
      title={t('notFound.pageTitle')}
      trackedTitle={t('notFound.pageTitle', { lng: 'en' })}
      showHeader={false}
    >
      <ErrorMessage
        title={t('notFound.title')}
        subtitle={t('notFound.message')}
        description={t('notFound.text.content')}
      />
    </Page>
  );
};

export default NotFound;
