const spacing = {
  tiny: '4px',
  xxsmall: '8px',
  xsmall: '16px',
  small: '24px',
  mediumLow: '32px',
  medium: '40px',
  mediumHigh: '48px',
  large: '56px',
  xlarge: '64px',
  xxlarge: '72px',
  huge: '80px',
  maxWidth: '1380px',
  headerHeight: '56px',
  bottomMenuHeight: '88px',
};

export default spacing;
