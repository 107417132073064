import { Button, ButtonColor, ParagraphStyleType } from '@stenametall/component-library';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Languages } from 'i18n/languageUtil';
import RadioButtonList from 'components/RadioButtonList';
import ChangeLanguageWrapper, {
  InnerContent,
  StyledParagraph,
  StyledParagraphSmallListTitle,
} from './styles';
import Page from 'components/Page';
import BackButton from 'components/BackButton';
import { routePaths } from 'routes/routePaths';

const ChangeLanguage = () => {
  const { t } = useTranslation();
  const [selectedLanguageId, setSelectedLanguageId] = useState<string>(i18next.languages[0]);

  useEffect(() => {
    setSelectedLanguageId(i18next.languages[0]);
  }, []);

  const handleOnChange = (e: SyntheticEvent) => {
    const { id } = e.currentTarget;
    setSelectedLanguageId(id);
  };

  const handleOnSave = () => {
    i18next.changeLanguage(selectedLanguageId);
  };

  const isSelectedSameAsActiveLanguage = () => {
    return selectedLanguageId === i18next.language;
  };

  const getLanguagesAsRadioItem = () => {
    return Object.entries(Languages).map(([key, lang]) => ({ id: key, title: lang.name }));
  };

  return (
    <Page
      title={t('userProfile.languageDialog.title')}
      trackedTitle={t('userProfile.languageDialog.title', { lng: 'en' })}
      leftActionButton={
        <BackButton to={routePaths.SETTINGS} state={{ from: routePaths.CHANGE_LANGUAGE }} />
      }
    >
      <ChangeLanguageWrapper>
        <InnerContent>
          <StyledParagraph styleType={ParagraphStyleType.body16}>
            {t('userProfile.languageDialog.subtitle')}
          </StyledParagraph>
          <StyledParagraphSmallListTitle styleType={ParagraphStyleType.body16}>
            {t('userProfile.languageDialog.list.title')}
          </StyledParagraphSmallListTitle>
          <RadioButtonList
            radioItems={getLanguagesAsRadioItem()}
            handleOnChange={handleOnChange}
            selectedId={selectedLanguageId}
          />
        </InnerContent>
        <Button
          onClick={handleOnSave}
          color={ButtonColor.blue}
          disabled={isSelectedSameAsActiveLanguage()}
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          {t('userProfile.languageDialog.button.text')}
        </Button>
      </ChangeLanguageWrapper>
    </Page>
  );
};

export default ChangeLanguage;
