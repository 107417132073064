import dayjs from 'dayjs';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderItemType } from 'types/order';
import { ParagraphStyleType } from 'components/Paragraph';
import OrderItemWrapper, {
  OrderItemLink,
  TitleRow,
  Text,
  Title,
  Description,
  StyledArrowRightIcon,
  InfoRow,
  Label,
  Value,
} from './styles';

export type OrderItemProps = {
  orderItem: OrderItemType;
  showOrderDetails: () => void;
};

export const getDatePart = (date: string) => dayjs(date).format('YYYY-MM-DD');

const OrderItem = ({ orderItem, showOrderDetails }: OrderItemProps) => {
  const { t } = useTranslation();
  const { id, title, description, numberOfItems, createdDate } = orderItem;
  const quantity = numberOfItems === -1 ? '-' : numberOfItems;

  const goToOrderDetails = (e: SyntheticEvent) => {
    e.preventDefault();
    showOrderDetails();
  };

  return (
    <OrderItemWrapper data-testid="order-item">
      <OrderItemLink onClick={goToOrderDetails}>
        <TitleRow>
          <Text>
            <Title styleType={ParagraphStyleType.body16} bold>
              {title || 'Övrigt'}
            </Title>
            <Description styleType={ParagraphStyleType.body14}>{description}</Description>
          </Text>
          <StyledArrowRightIcon />
        </TitleRow>

        <InfoRow>
          <div>
            <Label styleType={ParagraphStyleType.body12} upperCase>
              {t('userOrder.order.id')}
            </Label>
            <Value styleType={ParagraphStyleType.body12}>{`#${id}`}</Value>
          </div>
          <div>
            <Label styleType={ParagraphStyleType.body12} upperCase>
              {t('userOrder.order.date')}
            </Label>
            <Value styleType={ParagraphStyleType.body12}>{getDatePart(createdDate)}</Value>
          </div>
          <div>
            <Label styleType={ParagraphStyleType.body12} upperCase>
              {t('userOrder.quantity')}
            </Label>
            <Value styleType={ParagraphStyleType.body12}>{quantity}</Value>
          </div>
        </InfoRow>
      </OrderItemLink>
    </OrderItemWrapper>
  );
};

export default OrderItem;
