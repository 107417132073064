import { useTranslation } from 'react-i18next';
import { Location } from '@stenarecycling/customer-portal-types';
import Dialog from 'components/Dialog';
import ATag from 'components/ATag';
import { HeadingType, HeadingStyleType } from 'components/Heading';
import { ParagraphStyleType } from 'components/Paragraph';
import { Title, Info, Content, HazardousWasteInfoWrapper } from './styles';

export type HazardousWasteInfoProps = {
  onClose: () => void;
  isOpen: boolean;
  selectedLocation: Location | null;
};

const HazardousWasteInfo = (props: HazardousWasteInfoProps) => {
  const { isOpen, onClose } = props;

  const { t } = useTranslation();

  return (
    <HazardousWasteInfoWrapper>
      <Dialog isOpen={isOpen} onClose={onClose} fullscreen>
        <Content>
          <Title headingType={HeadingType.H4} styleType={HeadingStyleType.Heading4}>
            {t('placeOrder.hazardousWaste.title')}
          </Title>
          <Info styleType={ParagraphStyleType.body16}>{t('placeOrder.hazardousWaste.info1')}</Info>
          <Info styleType={ParagraphStyleType.body16}>
            {t('placeOrder.hazardousWaste.readMoreSweden')} <ATag>www.stenarecycling.se</ATag>.
          </Info>
        </Content>
      </Dialog>
    </HazardousWasteInfoWrapper>
  );
};

export default HazardousWasteInfo;
