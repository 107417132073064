import styled from '@emotion/styled';
import spacing from 'assets/spacing';
import { Link } from 'react-router-dom';

const BackButtonWrapper = styled(Link)`
  display: flex;
  padding-left: ${spacing.small};
`;

export default BackButtonWrapper;
