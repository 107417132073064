import styled from '@emotion/styled';
import spacing from 'assets/spacing';
import colors from 'assets/colors';
import Paragraph from 'components/Paragraph';

const OrderGroupWrapper = styled.div`
  padding: ${spacing.small};
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.compBeige_01};
  }
`;

export const SubGroupWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: ${spacing.mediumLow};
  }
`;

export const SubGroupLabel = styled(Paragraph)`
  color: ${colors.primGrey_01};
`;

export default OrderGroupWrapper;
