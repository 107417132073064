import styled from '@emotion/styled';
import spacing from 'assets/spacing';

const PlaceOrderWrapper = styled.div`
  padding-bottom: ${spacing.xxlarge};
  width: 100%;
  height: 100%;
`;

export const LoadingWrapper = styled.div`
  height: 45vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default PlaceOrderWrapper;
