import { LikedButton, ImgWrapper, TextParagraph } from './styles';
import Logo from 'assets/icons/thumb-up.svg?react';
import { ParagraphStyleType } from 'components/Paragraph';
export type LikeButtonProps = {
  children: string;
  isLiked?: boolean;
  isDisliked?: boolean;
  isActive?: boolean;
  thumbDown?: boolean;
  disabled?: boolean;
  onClick: () => void;
};

const LikeButton = ({
  children,
  thumbDown = false,
  onClick,
  isActive,
  disabled = false,
  ...rest
}: LikeButtonProps) => {
  return (
    <LikedButton isActive={isActive} disabled={disabled} onClick={onClick} {...rest}>
      <ImgWrapper isActive={isActive} thumbDown={thumbDown}>
        <Logo />
      </ImgWrapper>
      <TextParagraph styleType={ParagraphStyleType.body18} bold>
        {children}
      </TextParagraph>
    </LikedButton>
  );
};

export default LikeButton;
