import BackButton from 'components/BackButton';
import Page from 'components/Page';
import { useTranslation } from 'react-i18next';
import { routePaths } from 'routes/routePaths';
import CookiesWrapper from './styles';

const Cookies = () => {
  const { t } = useTranslation();

  return (
    <Page
      title={t('orderDetails.order')}
      trackedTitle={t('orderDetails.order', { lng: 'en' })}
      leftActionButton={
        <BackButton to={routePaths.PRIVACY_INFORMATION} state={{ from: routePaths.COOKIES }} />
      }
    >
      <CookiesWrapper>
        <h1>Cookies</h1>
      </CookiesWrapper>
    </Page>
  );
};

export default Cookies;
