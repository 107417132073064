import styled from '@emotion/styled';
import spacing from 'assets/spacing';
import { motion } from 'framer-motion';

type PageWrapperProps = {
  showHeader: boolean;
  hideMenu: boolean;
};
export const PageWrapper = styled(motion.div)<PageWrapperProps>`
  grid-column-start: 1;
  grid-row-start: 1;
  padding-top: ${({ showHeader }) => (showHeader ? spacing.headerHeight : 0)};
  padding-bottom: ${({ hideMenu }) => (hideMenu ? 0 : spacing.bottomMenuHeight)};
  height: 100%;
  width: 100vw;
  max-width: min(100vw, ${spacing.maxWidth});

  &.resetTransform {
    transform: none !important;
  }
`;
