import styled from '@emotion/styled';
import spacing from 'assets/spacing';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 56px);
`;

export const Title = styled(Heading)`
  margin-bottom: ${spacing.xsmall};
`;

export const InfoText = styled(Paragraph)`
  margin-bottom: 20px;
`;

export const ListTitle = styled(Paragraph)`
  margin-top: 20px;
  margin-bottom: ${spacing.xxsmall};
`;

export const InputWrapper = styled.div`
  margin-bottom: 20px;
`;
