import dayjs from 'dayjs';
import { Country } from 'types/location';
import { OrderItemType } from 'types/order';

const isRecentlyCompleted = (orderItem: OrderItemType): boolean => {
  const numberOfDays = 7;

  if (typeof orderItem.completedDate === 'string') {
    const today = dayjs();
    const orderCompletionDate = dayjs(orderItem.completedDate);
    return today.diff(orderCompletionDate, 'day') < numberOfDays;
  }

  return false;
};

export const isNotCompleted = (orderItem: OrderItemType): boolean => Number(orderItem.status) < 4;

const orderShouldBeIncluded = (orderItem: OrderItemType): boolean =>
  isNotCompleted(orderItem) || isRecentlyCompleted(orderItem);

const sortOrderItems = (orderItems: OrderItemType[]): OrderItemType[] => {
  return orderItems.sort((a, b) => {
    if (a.id === b.id) {
      return a.title.localeCompare(b.title);
    }
    return b.id.localeCompare(a.id);
  });
};

export const filterAndSortOrders = (orders: OrderItemType[]) =>
  sortOrderItems(orders.filter((order) => orderShouldBeIncluded(order)));

export enum StatusNumber {
  zero = '0',
  one = '1',
  two = '2',
  three = '3',
  four = '4',
  five = '5',
  ten = '10',
}

export const OrderStatusProccessStep = {
  isStepOne: (status: StatusNumber) => {
    return (
      status === StatusNumber.zero || status === StatusNumber.one || status === StatusNumber.two
    );
  },
  isStepTwo: (status: StatusNumber) => {
    return status === StatusNumber.three;
  },
  isStepThree: (status: StatusNumber) => {
    return (
      status === StatusNumber.four || status === StatusNumber.five || status === StatusNumber.ten
    );
  },
  statusIsAStep: (status: StatusNumber) => {
    return (
      status === StatusNumber.zero ||
      status === StatusNumber.one ||
      status === StatusNumber.two ||
      status === StatusNumber.three ||
      status === StatusNumber.four ||
      status === StatusNumber.five
    );
  },
};

export const hasValidPhoneNumber = (phone?: string | null): boolean => {
  if (!phone) {
    return false;
  }

  //Regex is from NVV(Hazardous waste reporting) and should not be altered.
  const regex =
    /^(([+][0-9]{1,3}[ .-]?)|([0-9]{4,5}[ .-]?))?([(]{1}[0-9]{1,6}[)])?([0-9 .-/]{3,20})$/;

  const isPhoneNumberValid = regex.test(phone);
  return isPhoneNumberValid;
};

export const hasValidName = (fullname?: string | null): boolean => {
  if (!fullname) {
    return false;
  }

  //Regex is from NVV(Hazardous waste reporting) and should not be altered.
  const regex = /(?=^.{3,250}$)^[',-.A-Z`a-zÀ-ÖØ-öø-ž](\s?[',-.A-Z`a-zÀ-ÖØ-öø-ž])+$/;
  const isNameValid = regex.test(fullname);
  return isNameValid;
};

export const articleExistsOnOrders = (
  articleId: string,
  orders?: OrderItemType[],
  country?: Country,
) => {
  if (!orders || !country) {
    return false;
  }

  const _articleId = country === Country.SWEDEN ? articleId : getShortArticleId(articleId);

  const foundMatch = orders?.find((order) => order.articleId.startsWith(_articleId));
  return !!foundMatch;
};

const getShortArticleId = (articleId: string) => {
  const idArray = articleId.split('-');
  return `${idArray[0]}-${idArray[1]}-${idArray[2]}`;
};
