import styled from '@emotion/styled';
import spacing from 'assets/spacing';
import Heading from 'components/Heading';

const ArticleGroupWrapper = styled.div``;

export default ArticleGroupWrapper;

export const StyledHeading = styled(Heading)`
  padding: ${spacing.xxsmall} 0;
`;
