import dayjs from 'dayjs';
import { useProfile, useAuth0 } from 'core';
import { type SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@stenametall/component-library/dist/components/Button';
import { useSelector } from 'utils/hooks/redux';
import colors from 'assets/colors';
import { Country } from 'types/location';
import { routePaths } from 'routes/routePaths';
import { selectLocations, selectSelectedLocation } from 'state/locationsSlice';
import { APP_VERSION } from 'helper/environment';
import Page from 'components/Page';
import { ParagraphStyleType } from 'components/Paragraph';
import { EasterEgg } from 'components/EasterEgg';
import forceReload from 'components/EasterEgg/easterEggs/forceReload';
import SettingsList, { LinkListItem, ListItem } from '../SettingsList';

import {
  ContentWrapper,
  StyledNameEmailParagraph,
  UserProfileWrapper,
  UserInfoWrapper,
  LogOutWrapper,
  AvatarCircle,
  StyledUserIcon,
} from './styles';

const UserProfileIndex = () => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const [profile] = useProfile();
  const locations = useSelector(selectLocations);
  const selectedLocation = useSelector(selectSelectedLocation);
  const hasLocations = () => locations.length > 0;

  const appVersionDateTime = dayjs.unix(Number(APP_VERSION)).format('YYYY-MM-DD, HH:mm');

  //Fix for bad types in used lib
  const typeFix = {} as {
    onPointerLeaveCapture: () => void;
    onPointerEnterCapture: () => void;
    placeholder: string;
  };

  return (
    <Page title={t('userProfile.settings')} trackedTitle={t('userProfile.settings', { lng: 'en' })}>
      <UserProfileWrapper>
        <ContentWrapper>
          <UserInfoWrapper>
            <AvatarCircle>
              <StyledUserIcon />
            </AvatarCircle>
            <div>
              <StyledNameEmailParagraph
                styleType={ParagraphStyleType.body16}
                fontColor={colors.black}
              >
                {profile?.fullName}
              </StyledNameEmailParagraph>
              <StyledNameEmailParagraph
                styleType={ParagraphStyleType.body14}
                fontColor={colors.primGrey_00}
              >
                {profile?.email}
              </StyledNameEmailParagraph>
            </div>
          </UserInfoWrapper>
          <SettingsList subHeader={t('userProfile.generalSettings.header')}>
            <LinkListItem
              title={t('userProfile.language.title')}
              description={t('userProfile.language.description')}
              to={routePaths.CHANGE_LANGUAGE}
              state={{ from: routePaths.SETTINGS }}
            />
          </SettingsList>
          <SettingsList subHeader={t('userProfile.contact.header')}>
            {hasLocations() && selectedLocation?.country === Country.SWEDEN && (
              <LinkListItem
                title={t('userProfile.branch.title')}
                description={t('userProfile.branch.description')}
                to={routePaths.YOUR_BRANCH}
                state={{ from: routePaths.SETTINGS }}
              />
            )}

            <LinkListItem
              title={t('userProfile.customerService.title')}
              description={t('userProfile.customerService.description')}
              to={routePaths.CUSTOMER_SERVICE}
              state={{ from: routePaths.SETTINGS }}
            />

            <LinkListItem
              title={t('userProfile.feedback.title')}
              description={t('userProfile.feedback.description')}
              to={routePaths.FEEDBACK_AND_HELP}
              state={{ from: routePaths.SETTINGS }}
            />
          </SettingsList>
          <SettingsList subHeader={t('userProfile.about.header')}>
            <LinkListItem
              title={t('userProfile.privacy.title')}
              description={t('userProfile.privacy.description')}
              to={routePaths.PRIVACY_INFORMATION}
              state={{ from: routePaths.SETTINGS }}
            />
            <EasterEgg easterEggs={[forceReload]}>
              <ListItem
                title={t('userProfile.appVersion.title')}
                description={`${APP_VERSION} (${appVersionDateTime})`}
              />
            </EasterEgg>
          </SettingsList>
          <LogOutWrapper>
            <Button
              onClick={async (e: SyntheticEvent) => {
                e.preventDefault();
                await logout({ logoutParams: { returnTo: window.location.origin } });
              }}
              {...typeFix}
            >
              {t('userProfile.log.out')}
            </Button>
          </LogOutWrapper>
        </ContentWrapper>
      </UserProfileWrapper>
    </Page>
  );
};

export default UserProfileIndex;
