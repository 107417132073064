import { useEffect } from 'react';

export const useEventListener = (
  type: string,
  listener: (event: Event) => void,
  eventTarget: EventTarget = window,
) =>
  useEffect(() => {
    let eventTargetElement: EventTarget | null = eventTarget;

    if ('current' in eventTarget) {
      eventTargetElement = (eventTarget as unknown as React.RefObject<HTMLElement>).current;
    }

    if (!eventTargetElement) {
      return;
    }

    eventTargetElement.addEventListener(type, listener);
    return () => {
      if (eventTargetElement) {
        eventTargetElement.removeEventListener(type, listener);
      }
    };
  }, [eventTarget, type, listener]);
