import styled from '@emotion/styled';
import spacing from 'assets/spacing';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';
import GlobeIcon from 'assets/icons/globe.svg?react';
import ChevronIcon from 'assets/icons/chevron-right.svg?react';
import colors from 'assets/colors';
import fontWeights from 'assets/fontWeights';
import fontSizes from 'assets/fontSizes';
import { Link } from 'react-router-dom';

const PrivacyInformationWrapper = styled.div`
  background-color: ${colors.white};
  min-height: 100%;
`;

export const Title = styled(Heading)`
  padding-left: ${spacing.small};
  padding-right: ${spacing.small};
  padding-top: ${spacing.medium};
  padding-bottom: ${spacing.xsmall};
`;
export const StyledParagraph = styled(Paragraph)`
  padding-left: ${spacing.small};
  padding-right: ${spacing.small};
  padding-bottom: ${spacing.mediumLow};
`;

export const StyledGlobeIcon = styled(GlobeIcon)`
  path {
    stroke: ${colors.compBlue_00};
  }
  circle {
    stroke: ${colors.compBlue_00};
  }
`;
export const StyledChevronIcon = styled(ChevronIcon)`
  path {
    stroke: ${colors.compBlue_00};
  }
  circle {
    stroke: ${colors.compBlue_00};
  }
`;

export const BlockATag = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: StenaSans;
  font-style: normal;
  font-weight: ${fontWeights.normal};
  font-size: ${fontSizes.body16};
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${colors.black};
  text-decoration: none;

  width: 100%;
  padding: ${spacing.small};
  margin: 0;
  background-color: ${colors.white};
  text-align: left;
  border: none;
  border-bottom: 1px solid ${colors.primGrey_03};
  &:first-of-type {
    border-top: 1px solid ${colors.primGrey_03};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const BlockLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: StenaSans;
  font-style: normal;
  font-weight: ${fontWeights.normal};
  font-size: ${fontSizes.body16};
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${colors.black};
  text-decoration: none;

  width: 100%;
  padding: ${spacing.small};
  margin: 0;
  background-color: ${colors.white};
  text-align: left;
  border: none;
  border-bottom: 1px solid ${colors.primGrey_03};

  &:hover {
    cursor: pointer;
  }
`;
export default PrivacyInformationWrapper;
