import styled from '@emotion/styled';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';
import spacing from 'assets/spacing';
import fontSizes from 'assets/fontSizes';

export const HazardousWasteInfoWrapper = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled(Heading)`
  margin-bottom: ${spacing.xsmall};
`;

export const Info = styled(Paragraph)`
  margin-top: ${spacing.xsmall};
  a {
    font-size: ${fontSizes.body16};
  }
`;
