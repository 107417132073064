import styled from '@emotion/styled';
import colors from 'assets/colors';
import spacing from 'assets/spacing';
import Icon from 'assets/icons/install.svg?react';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';

export const PlusIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: ${spacing.mediumLow};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  padding-bottom: ${spacing.small};
`;

export const StenaLogoWrapped = styled.div`
  padding: 0 ${spacing.xsmall} 0 0;
`;

export const ShareIcon = styled(Icon)`
  position: relative;
  top: 6px;
  left: 2px;
  path {
    fill: ${colors.compBlue_00};
  }
`;

export const StyledHeading = styled(Heading)`
  padding-bottom: ${spacing.xsmall};
`;

export const StyledParagraph = styled(Paragraph)`
  padding-top: ${spacing.mediumLow};
  padding-bottom: ${spacing.mediumLow};
`;

export const LongLine = styled.div`
  width: calc(100% + ${spacing.small} + ${spacing.small});
  height: 1px;
  background: ${colors.primGrey_03};
  position: relative;
  top: 0px;
  left: -${spacing.small};
  margin-bottom: ${spacing.small};
`;

export const Instruction = styled(Paragraph)`
  margin-top: ${spacing.xsmall};
`;
