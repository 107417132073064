import dayjs from 'dayjs';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { selectLocations } from 'state/locationsSlice';
import { RootState } from 'state/store';
import { SendOrderResponse, OrderItemsDictionary } from 'types/order';
import { GetOrdersParams, getOrders, postOrder, SendOrderTypeWithToken } from 'api/orders';

export const sendOrder = createAsyncThunk(
  'orders/sendOrder',
  async (order: SendOrderTypeWithToken, thunkApi) =>
    postOrder(order).catch((error) => thunkApi.rejectWithValue(error.message)),
);

type FetchOrdersProps = {
  token: string;
  transactionId: string;
  partnerId: string;
  userEmail: string;
};

export const fetchOrders = createAsyncThunk<
  OrderItemsDictionary,
  FetchOrdersProps,
  { rejectValue: Error }
>('orders/fetchOrders', async (params, thunkApi) => {
  const partnerId = params.partnerId === 'all' ? undefined : params.partnerId;
  const threeMonthsAgo = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
  const today = dayjs().format('YYYY-MM-DD');
  const token = params.token;
  const transactionId = params.transactionId;
  const userEmail = params.userEmail;
  return getOrders({
    token,
    transactionId,
    userEmail,
    partnerId,
    from: threeMonthsAgo,
    to: today,
  }).catch((error) => {
    return thunkApi.rejectWithValue(error.message);
  });
});

export const fetchAllOrders = createAsyncThunk<
  OrderItemsDictionary,
  GetOrdersParams,
  { rejectValue: Error }
>('orders/fetchAllOrders', async (params, thunkApi) => {
  const userEmail = params.userEmail || undefined;
  const partnerId = params.partnerId === 'all' ? undefined : params.partnerId;
  const threeMonthsAgo = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
  const token = params.token;
  const transactionId = params.transactionId;

  return getOrders({ token, transactionId, userEmail, partnerId, from: threeMonthsAgo }).catch(
    (error) => {
      return thunkApi.rejectWithValue(error.message);
    },
  );
});

interface OrdersState {
  orderStatus: SendOrderResponse | null;
  sendOrderPending: boolean;
  errorSending: string | null;
  orders: OrderItemsDictionary;
  allOrders: OrderItemsDictionary;
  selectedOrderInfo: {
    orderId: string | null;
    locationId: string | null;
    articleId: string | null;
  };
  loading: boolean[];
  loadingAllOrders: boolean[];
  errorLoading: string | null;
  errorLoadingAllOrders: string | null;
}

export const initialOrdersState: OrdersState = {
  orderStatus: null,
  sendOrderPending: false,
  errorSending: null,
  orders: {},
  allOrders: {},
  selectedOrderInfo: {
    orderId: null,
    locationId: null,
    articleId: null,
  },
  loading: [],
  loadingAllOrders: [],
  errorLoading: null,
  errorLoadingAllOrders: null,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrdersState,
  reducers: {
    setSelectedOrder: (
      state,
      action: PayloadAction<{ orderId: string; locationId: string; articleId: string }>,
    ) => {
      state.selectedOrderInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOrder.pending, (state) => {
        state.sendOrderPending = true;
      })
      .addCase(sendOrder.fulfilled, (state, { payload: sendOrderResponse }) => {
        state.orderStatus = sendOrderResponse as SendOrderResponse;
        state.sendOrderPending = false;
        state.errorSending = null;
      })
      .addCase(sendOrder.rejected, (state, { payload: errorMessage }) => {
        state.errorSending = errorMessage as string;
        state.orderStatus = null;
        state.sendOrderPending = false;
      })
      .addCase(fetchOrders.pending, (state) => {
        state.loading.push(true);
      })
      .addCase(fetchOrders.fulfilled, (state, { payload: orders }) => {
        state.orders = { ...state.orders, ...orders };
        state.loading.pop();
      })
      .addCase(fetchOrders.rejected, (state, { payload }) => {
        if (payload?.message) {
          state.errorLoading = payload.message;
        } else {
          state.errorLoading = 'An error occurred while fetching orders.';
        }

        state.loading.pop();
      })
      .addCase(fetchAllOrders.pending, (state) => {
        state.loadingAllOrders.push(true);
      })
      .addCase(fetchAllOrders.fulfilled, (state, { payload: orders }) => {
        state.allOrders = orders;
        state.loadingAllOrders.pop();
      })
      .addCase(fetchAllOrders.rejected, (state, { payload }) => {
        if (payload?.message) {
          state.errorLoadingAllOrders = payload.message;
        } else {
          state.errorLoadingAllOrders = 'An error occurred while fetching all orders.';
        }

        state.loadingAllOrders.pop();
      });
  },
});

export const { setSelectedOrder } = ordersSlice.actions;

export const selectOrderStatus = (state: RootState) => state.orders.orderStatus;
export const selectSendOrderPending = (state: RootState) => state.orders.sendOrderPending;
export const selectErrorSending = (state: RootState) => state.orders.errorSending;
export const selectOrders = (state: RootState) => state.orders.orders;
export const selectAllOrders = (state: RootState) => state.orders.allOrders;
const selectSelectedOrderInfo = (state: RootState) => state.orders.selectedOrderInfo;
const selectSelectedOrderLocationId = (state: RootState) =>
  selectSelectedOrderInfo(state).locationId;
export const selectLoadingOrders = (state: RootState) => state.orders.loading;
export const selectLoadingError = (state: RootState) => state.orders.errorLoading;
export const selectLoadingAllOrders = (state: RootState) => state.orders.loadingAllOrders;
export const selectLoadingAllOrdersError = (state: RootState) => state.orders.errorLoadingAllOrders;
export const selectSelectedOrder = createSelector(
  [selectOrders, selectSelectedOrderInfo],
  (orders, { orderId, locationId, articleId }) => {
    if (!orderId || !locationId || !articleId) {
      return null;
    }
    const ordersOnLocation = orders[locationId];
    return ordersOnLocation.find((order) => order.id === orderId && order.articleId === articleId);
  },
);

export const selectSelectedOrderLocation = createSelector(
  [selectLocations, selectSelectedOrderLocationId],
  (locations, locationId) => locations.find((location) => location.dwKey === locationId),
);

export default ordersSlice.reducer;
