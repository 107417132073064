import axios, { AxiosRequestConfig } from 'axios';
import { environment } from 'helper/environment';
import { PayloadCreator } from 'types/generic';
import { createLocations } from 'utils/locationUtil';
import { PartnerResponse, StenaPermissions } from '@stenarecycling/customer-portal-types';

export const getLocations = async (
  token: string,
  transactionId: string,
  permissions: StenaPermissions,
  { rejectWithValue }: PayloadCreator,
) => {
  const requestConfig: AxiosRequestConfig = {};

  if (token) {
    requestConfig.headers = { authorization: `Bearer ${token}`, 'X-Transaction-ID': transactionId };
  }

  return axios
    .get(`${environment.newApi.url}${environment.newApi.path.partners}`, requestConfig)
    .then((response) => {
      const allPartnerIdsInPermissions = permissions.businessPartners
        .filter((bp) => bp.services.some((s) => s.name === 'services.order.pickup.name'))
        .flatMap((bp) => [bp.id, ...(bp.expandedPartners || [])]);

      const partners = (response.data as PartnerResponse[]).filter((p) =>
        allPartnerIdsInPermissions.some((bp) => bp === p.dwKey),
      );

      return createLocations(partners);
    })
    .catch((error) => {
      return rejectWithValue(error?.response?.data as Error);
    });
};
