import { matchPath } from 'react-router-dom';
import { routePaths } from 'routes/routePaths';
import { type Location } from 'history';
import { type TFunction } from 'i18next';

export const getViewName = (routePath: string, t: TFunction) => {
  if (matchPath(routePaths.INTRODUCTION, routePath)) {
    return t('introduction.pageTitle');
  }
  if (matchPath(routePaths.LOGIN, routePath)) {
    return t('login.pageTitle');
  }
  if (matchPath(routePaths.PLACE_ORDER, routePath)) {
    return t('placeOrder.pageTitle');
  }
  if (matchPath(routePaths.CONFIRMATION, routePath)) {
    return t('confirmation.pageTitle.success');
  }
  if (matchPath(routePaths.USER_ORDERS, routePath)) {
    return t('userOrders.pageTitle');
  }
  if (matchPath(routePaths.USER_ORDERS_DETAILS, routePath)) {
    return t('orderDetails.pageTitle');
  }
  if (matchPath(routePaths.SETTINGS, routePath)) {
    return t('userProfile.pageTitle');
  }

  return 'Unknown route';
};

export const isCurrentRoute = (routePath: string, location: Location): boolean => {
  return matchPath(routePath, location.pathname) !== null;
};

export const isValidRoute = (location: Location): boolean => {
  const routePathsArray = Object.values(routePaths);
  const validPaths = routePathsArray.filter((routePath) => routePath !== routePaths.NOT_FOUND);

  return validPaths.some((routePath) => isCurrentRoute(routePath, location));
};
